import { ChangeDetectionStrategy, Component, HostListener, Input } from '@angular/core';

import { User, UserPerfil } from '../../../shared/interfaces/user.interface';
import { AuthFacade } from '../../../registration/+state/auth.facade';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  UserPerfil = UserPerfil;
  @Input() user: User;
  @Input() isLoggedIn: boolean;

  private wasInside = false;
  status = false;

  constructor(private authFacade: AuthFacade) {
  }

  toggleMenu(): void {
    this.status = !this.status;
    this.wasInside = !this.wasInside;
  }

  logout(): void {
    this.authFacade.logout();
  }

  @HostListener('document:click')
  clickOut(): void {
    if (!this.wasInside && this.status === true) {
      this.toggleMenu();
    }
    this.wasInside = false;
  }

}
