import { Component, OnInit } from '@angular/core';
import { AuthFacade } from '../../../registration/+state/auth.facade';
import { Observable } from 'rxjs';
import { District, Region, User, UserPerfil } from '../../../shared/interfaces/user.interface';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidateBrService } from 'angular-validate-br';
import { RegistrationService } from '../../../registration/registration.service';
import { ProductsService } from '../../../products/products.service';

@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.scss']
})
export class DataComponent implements OnInit {

  user$: Observable<User>;
  isLoggedIn$: Observable<boolean>;
  regions$: Observable<Region[]>;
  districts$: Observable<District[]>;

  dataForm: FormGroup;
  submitted = false;
  UserPerfil = UserPerfil;
  newDistricts: any[];
  regionsFromCRM: any[] = [];
  districtsFromCRM: any[] = [];
  arr_ids_districts: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private authFacade: AuthFacade,
    private validateBrService: ValidateBrService,
    private registrationService: RegistrationService,
    private productService: ProductsService) {
  }

  ngOnInit(): void {

    let url = window.location.host;
    let urlSplit = url.split('.');
    let company = urlSplit[0];

    this.user$ = this.authFacade.user$;
    this.isLoggedIn$ = this.authFacade.isLoggedIn$;
    this.regions$ = this.registrationService.getRegions();
    this.districts$ = this.registrationService.getDistricts();

    this.user$.subscribe(
      user => {
        this.newDistricts = user.districts;
        if(user.ids_districts){
          this.arr_ids_districts = user.ids_districts.split(",");
          this.newDistricts = user.ids_districts.split(",");
        }

        let dataFormPart;
        if (user.perfil === UserPerfil.AUTONOMO) {
          dataFormPart = {nome: [user.nome], cpf: [user.cpf, [this.validateBrService.cpf]]};
        } else {
          dataFormPart = {razao_social: [user.razao_social], cnpj: [user.cnpj, [this.validateBrService.cnpj]]};
        }

        this.dataForm = this.formBuilder.group({
          id: [user.id],
          email: [user.email, [
            Validators.required,
            Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]
          ],
          telefone: [user.telefone],
          creci: [user.creci],
          perfil: [user.perfil],
          ...dataFormPart
        });
      }
    );
    
    this.productService.getRegionFromCRM(company).then(regions => {
      for (let index = 0; index < regions['Regioes'].length; index++) {
        const element = regions['Regioes'][index];
        this.regionsFromCRM.push(element);
      } 
    });

    this.productService.getDistrictFromCRM(company).then(districts => {
      for (let index = 0; index < districts['Bairros'].length; index++) {
        const element = districts['Bairros'][index];
        this.districtsFromCRM.push(element);
      } 
    });


  }

  toggleAccordion(event, index): void {
    const element = event.target;
    event.stopPropagation();
    element.classList.toggle('active');

    const panel = element.nextElementSibling;

    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + 'px';
    }
  }

  get f(): { [key: string]: AbstractControl; } {
    return this.dataForm.controls;
  }

  addDistrict(newDistrict: District): void {

    if(this.newDistricts){
      this.newDistricts = this.newDistricts?.find(district => district.id === newDistrict.id) ?
        this.newDistricts?.filter(district => district.id !== newDistrict.id) : [...this.newDistricts, newDistrict];
    }

    if(newDistrict){
      if(this.arr_ids_districts.includes(newDistrict["id_bairro"])){
        var index = this.arr_ids_districts.indexOf(newDistrict["id_bairro"]);
        if (index !== -1) {
          this.arr_ids_districts.splice(index, 1);
        }
      }else{
        this.arr_ids_districts.push(newDistrict["id_bairro"]);
      }
    }
  }

  save(): void {
    this.submitted = true;
    if (this.dataForm.valid) {
      var string_ids_districts = null;
      string_ids_districts = this.arr_ids_districts.join(",");
      this.authFacade.update({...this.dataForm.value, districts: this.newDistricts, ids_districts: string_ids_districts});
    }
  }

  logout(): void {
    this.authFacade.logout();
  }
}
