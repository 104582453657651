<div class="register">
  <div class="register__modal">

    <app-company-logo [class]="'logo'" [link]="''"></app-company-logo>

    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="form__default login__form">

      <div class="form-group">
        <label [ngClass]="{ 'is-invalid-label': submitted && f.identifier.errors }">Usuário</label>
        <input type="text" formControlName="identifier" [ngClass]="{ 'is-invalid': submitted && f.identifier.errors }"
               required>
        <div *ngIf="submitted && f.identifier.errors" class="invalid-feedback">
          <div *ngIf="f.identifier.errors.required">Campo Obrigatório</div>
        </div>
      </div>

      <div class="form-group">
        <label [ngClass]="{ 'is-invalid-label': submitted && f.password.errors }">Senha</label>
        <input type="password" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
               required>
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
          <div *ngIf="f.password.errors.required">Campo obrigatório</div>
          <div *ngIf="f.password.errors.minlength">Necessário pelo menos 6 dígitos</div>
        </div>
      </div>

      <a routerLink="/cadastro/recupera-senha" class="link__default--primary">Esqueci minha senha</a>

      <button class="btn_solid--blocked--primary" type="submit">Entrar</button>

      <app-button
        routerLink="/cadastro/selecionar"
        [label]="'Ainda não tem uma conta? cadastre-se.'"
        [btnstyle]="'btn_solid--blocked--dark'"></app-button>

    </form>
  </div>
</div>

