// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api_url: 'https://info.hypnoboxparcerias.com.br',
  //api_products_url: 'https://parcerias.hypnobox.com.br/api',
  api_products_url : 'http://ryazbek-parceria.hypnobox.com.br/api',
  api_products_url_ryazbek : 'http://ryazbek-parceria.hypnobox.com.br/api',
  password_ryazbek: 'exPzAhOGyWfICSVbPr',
  password_gamaro: 'OaKqEEZRiKaWyfIDrz',
  password_fibra: 'zOyPVulDHnlTJeOvJf',
  password_cury: 'pyTXPSUGZChlRQnuFa',
  password_curyrj: 'XpdWDqgVxQUuRToOVn',
  password_skr: 'hNyRxiGdLyVmAnaaBr',
  password_sequoia: 'NKVwhnWQJQteEaKokR',
  password_exto: 'pbxHZuPoaeobwgYkpn',
  password_parcerias: 'xtJmvuOWpPbbyoZSAK',
  password_start: 'NHWBcVNVxKzdYGdEyZ',
  password_mitre: 'sQWhTuPtvRIxljSpXS',
  password_alfa: 'eItvKlABngIMHYwSwj',
  password_steel: 'QDHJWLkTawDxskYQYC',
  password_aam: 'E22HYP!vU@m0xbWcXj89',
  password_paesegregori: 'sATViDjkkYvUfgLfUG',
  password_canopus: 'uGPJQZpULiOKrJYVeB',
  password_fraiha: 'iOlKUEJhXJTorFjVsP',
  password_cs: 'xIxVcIeWtBewJvLRJr',
  password_wvmaldi: 'atbRKDyRNTTteZTwWo',
  password_altti: 'dKzWmLPtuodWXGFjIx',
  password_planik: 'jFYLUfwpfLEeCyKnpV',
  password_brasilincorp: 'McJVSZsQsdmUysLJeG'


  
  
  //api_products_url_ryazbek: 'https://ryazbek-parceria.hypnobox.com.br/api',
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
