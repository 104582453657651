<section class="help">
  <div class="container">
    <h1>Falar com o Coordenador</h1>

    <div class="help__wrapper">
      <div>
        <app-avatar [name]="'Maria Alice'" [image]="'/assets/images/avatar-woman.jpg'"></app-avatar>

        <ul class="contacts hide-on-xs">
          <li class="contacts__email"><a href="mailto:mariaalice@hypnobox.com.br">mariaalice@hypnobox.com.br</a></li>
          <li class="contacts__phone">11 9898 05446</li>
          <li class="contacts__whats">11 9898 05446</li>
        </ul>
      </div>

      <form action="" class="form__default">
        <textarea name="mensagem" placeholder="Mensagem" rows="18"></textarea>
        <button>Enviar</button>
      </form>

      <ul class="contacts show-on-xs">
        <li class="contacts__email"><a href="mailto:mariaalice@hypnobox.com.br">mariaalice@hypnobox.com.br</a></li>
        <li class="contacts__phone">11 9898 05446</li>
        <li class="contacts__whats">11 9898 05446</li>
      </ul>
    </div>

  </div>
</section>

