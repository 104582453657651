import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductsComponent } from './products.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { AuthGuardService } from '../shared/services/auth-guard.service';

const routes: Routes = [
  {
    path: 'imoveis',
    component: ProductsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'imovel/:codProduto',
    component: ProductDetailComponent,
    canActivate: [AuthGuardService],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class ProductsRoutingModule {
}
