<div class="register">
  <ng-container *ngIf="districtsFromCRM.length;else other_content">
    <div class="register__modal" *ngIf="(districtsFromCRM) let districts">
  
      <app-company-logo [class]="'logo'" [link]="'/logado'"></app-company-logo>
  
      <h1>Selecione suas áreas de atuação:</h1>
  
      <div class="form__default login__form">
  
        <ng-container *ngFor="let region of regionsFromCRM">
  
          <div class="acc__header" (click)="toggleAccordion($event, 0);">{{region.nome}}</div>
          <div class="acc">
            <div class="acc__content">
              <ng-container *ngFor="let district of districtsFromCRM">
                <div *ngIf="district.id_regiao == region.id_regiao" class="acc__content--item">
                  <div class="checkbox">
                    <input (click)="addDistrict(district)" class="checkbox__input" id="checkbox_a{{district.id_bairro}}"
                           type="checkbox" value="{{district.id_bairro}}">
                    <label for="checkbox_a{{district.id_bairro}}" class="checkbox__label">{{district.nome}}</label>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
  
        <app-button
          (click)="avancar()"
          [label]="'Avançar'"
          [btnstyle]="'btn_solid--wider--primary'"></app-button>
  
        <app-button
          routerLink="/cadastro/registra-senha"
          [label]="'Voltar'"
          [btnstyle]="'btn_solid--wider--white'"></app-button>
  
      </div>
  
      <app-register-pagination [dots]="4" [active]="4"></app-register-pagination>
    </div>
  </ng-container>
  <ng-template #other_content>
    <div class="register__modal" *ngIf="(districts$ | async) let districts">

      <app-company-logo [class]="'logo'" [link]="'/logado'"></app-company-logo>
  
      <h1>Selecione suas áreas de atuação:</h1>
  
      <div class="form__default login__form">
  
        <ng-container *ngFor="let region of regions$ | async">
  
          <div class="acc__header" (click)="toggleAccordion($event, 0);">{{region.name}}</div>
          <div class="acc">
            <div class="acc__content">
              <ng-container *ngFor="let district of districts">
                <div *ngIf="district?.region?.id == region.id" class="acc__content--item">
                  <div class="checkbox">
                    <input (click)="addDistrict(district)" class="checkbox__input" id="checkbox_a{{district.id}}"
                           type="checkbox">
                    <label for="checkbox_a{{district.id}}" class="checkbox__label">{{district.name}}</label>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
  
        <app-button
          (click)="avancar()"
          [label]="'Avançar'"
          [btnstyle]="'btn_solid--wider--primary'"></app-button>
  
        <app-button
          routerLink="/cadastro/registra-senha"
          [label]="'Voltar'"
          [btnstyle]="'btn_solid--wider--white'"></app-button>
  
      </div>
  
      <app-register-pagination [dots]="4" [active]="4"></app-register-pagination>
    </div>
  </ng-template>
</div>
