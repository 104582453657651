import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild, NgZone, Input } from '@angular/core';
import { ScrollToAnimationEasing } from '@nicky-lenaers/ngx-scroll-to';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MapsAPILoader, AgmMap, GoogleMapsAPIWrapper } from '@agm/core';

import { Product } from '../../shared/interfaces/product.interface';
import { ProductsService } from '../../products/products.service';
import { ProductsFacade } from '../+state/products.facade';
import { ActivatedRoute } from '@angular/router';
import { User } from '../../shared/interfaces/user.interface';
import { AuthFacade } from '../../registration/+state/auth.facade';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { ModalImgProductComponent } from 'src/app/components/modal-img-product/modal-img-product.component';


declare var google: any;

interface Location {
  lat?: number;
  lng?: number;
  viewport?: Object;
  zoom: number;
  address_level_1?:string;
  address_level_2?: string;
  address_country?: string;
  address_zip?: string;
  address_state?: string;
  // marker?: Marker;
}

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit, AfterViewInit {

  @ViewChild('stickyMenu') menuElement: ElementRef;
  @ViewChild('fulladdress') input: any;
  sticky = false;
  elementPosition: any;
  diff: number;
  codProduto: string;
  product$: Observable<Product>;
  acessoLiberado: boolean;
  user$: Observable<User>;

  geocoder:any;
  mapInfos = false;
  fillColor = '#95BF4A';
  strokeWeight = 2;
  title = 'My first AGM project';
  lat = -22.9065969;
  lng = -47.0544279;
  circleRadius = 500;

  public location:Location = {
    lat: 0.0,
    lng: 0.0,
    zoom: 15
  };
  @ViewChild(AgmMap) map: AgmMap;

  ngxScrollToEasing: ScrollToAnimationEasing;
  ngxScrollToOffset: number;
  ngxScrollToDuration: number;

  hideModal = {
    popup: 'swal2-hide', backdrop: 'swal2-hide-bg'
  };
  showModal = {
    popup: 'swal2-hide', backdrop: 'swal2-hide-bg'
  };

  constructor(
    private productsFacade: ProductsFacade,
    private route: ActivatedRoute,
    public mapsApiLoader: MapsAPILoader,
    private zone: NgZone,
    private wrapper: GoogleMapsAPIWrapper,
    private productsService: ProductsService,
    private authFacade: AuthFacade,
    public dialog: MatDialog
    ) {
      
    let url = window.location.host;
    let urlSplit = url.split('.');
    let company = urlSplit[0];
    //this.acessoLiberado = false;

    this.productsService.getModule(company, 'acesso-portal-parceiros').then(x => {
      if(x['Modulos']['acesso-portal-parceiros'] == 1){

        this.user$ = this.authFacade.user$;
        this.user$.subscribe(
          user => {
            this.productsService.getClientInfoByEmail(company, user.email).then(y => {
              if(y['Cliente'] !== undefined){
                if(y['Cliente'][0]?.acesso_portal == 1){
                  this.acessoLiberado = true;
                }else{
                  this.acessoLiberado = false;
                }
              }else{
                this.acessoLiberado = false;
              }
            });
          }
        );

      }else{
        this.acessoLiberado = true;
      }
    });
    
    this.ngxScrollToEasing = 'easeInOutQuint';
    this.ngxScrollToOffset = -180;
    this.ngxScrollToDuration = 1200;

    this.codProduto = this.route.snapshot.paramMap.get('codProduto');
    this.product$ = this.productsFacade.products$
      .pipe(map(products => products.find(
        product => product.CodProduto === this.codProduto)));

    this.productsFacade.getProducts({company});

    this.mapsApiLoader = mapsApiLoader;
    this.zone = zone;
    this.wrapper = wrapper;
    this.mapsApiLoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder();
    });
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.elementPosition = this.menuElement.nativeElement.offsetTop;
      this.findLocation(this.input.nativeElement.value);
      this.mapInfos = true;
    }, 1000);
    this.diff = (window.innerWidth > 992) ? 75 : 55;
  }

  findLocation(address) {
    if (!this.geocoder) this.geocoder = new google.maps.Geocoder()
    this.geocoder.geocode({'address': address }, (results, status) => {
      //console.log('Results: ', results);
      if (status == google.maps.GeocoderStatus.OK) {
        for (var i = 0; i < results[0].address_components.length; i++) {
          let types = results[0].address_components[i].types

          if (types.indexOf('locality') != -1) {
            this.location.address_level_2 = results[0].address_components[i].long_name
          }
          if (types.indexOf('country') != -1) {
            this.location.address_country = results[0].address_components[i].long_name
          }
          if (types.indexOf('postal_code') != -1) {
            this.location.address_zip = results[0].address_components[i].long_name
          }
          if (types.indexOf('administrative_area_level_1') != -1) {
            this.location.address_state = results[0].address_components[i].long_name
          }
        }

        if (results[0].geometry.location) {
          this.location.lat = results[0].geometry.location.lat();
          this.location.lng = results[0].geometry.location.lng();
          this.location.viewport = results[0].geometry.viewport;
        }
        this.map.triggerResize();

      } else {
        //console.log('endereço não encontrado');
      }
    })
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.diff = (window.innerWidth > 992) ? 75 : 55;
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll(): void {
    const windowScroll = window.pageYOffset;
    this.sticky = windowScroll >= this.elementPosition - this.diff;
  }

  modalCopied(): void {
    Swal.fire({
      icon: 'success',
      title: 'Boa!',
      text: 'Copiado o link de vendas.',
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true
    });
  }


  openModal(linkImg:any, titulo:any, descricao:any) {
    this.dialog.open(ModalImgProductComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        linkProductImg: linkImg,
        titulo: titulo,
        descricao: descricao
        
      }
    });
  }

  processDescription(description: string): string {
    return description.replace(/\r?\n/g, '<br/>');
  }
}
