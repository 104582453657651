<div class="avatar">
  <div class="avatar__image">
    <img src="{{ image }}" alt="{{ name }}">
  </div>
  <div class="avatar__description">
    <span>Fale com o seu coordenador</span>
    <span>{{ name }}</span>
  </div>
</div>

