<section class="logged "> <!-- header_padding -->
  <div class="container">

    <!-- MAIN -->

    <ng-container *ngIf="acessoLiberado == false;">

      <div class="logged__main">
        <h1>Seja Bem-Vindo(a)</h1>
        <h2>A sua solicitação de acesso aos dados do nosso portal está em análise.</h2> 
        <h3>Em breve você será notificado por e-mail.</h3>
      </div>

    </ng-container>

    <ng-container *ngIf="!acessoLiberado == false;">

      <div class="logged__main">
        <h1>Seja Bem-Vindo(a)</h1>
        <div class="logged__preslider">
          <h3>Últimos Imóveis Adicionados</h3>
          <app-button
            [btnstyle]="'btn_ghost--dark hide-on-xs'"
            [label]="'Ver Todos'"
            routerLink="/imoveis"></app-button>
        </div>
  
  
        <div class="logged__slider">
          <ngx-slick-carousel [config]="slideConfig" class="carousel">
            <div *ngFor="let product of products$ | async" class="slide" ngxSlickItem>
              <app-box
              [image]="product.FotoCapa?.Caminho"
              [link]="'/imovel/'+product.CodProduto"
  
              [room]="product.UnidadesDeAte.TotalDormitorioDe === product.UnidadesDeAte.TotalDormitorioAte ? 
              product.UnidadesDeAte.TotalDormitorioDe : product.UnidadesDeAte.TotalDormitorioDe + ' a ' 
              + product.UnidadesDeAte.TotalDormitorioAte"
  
              [size]="product.UnidadesDeAte.TotalAreaUtilDe === product.UnidadesDeAte.TotalAreaUtilAte ?
              product.UnidadesDeAte.TotalAreaUtilDe:
              product.UnidadesDeAte.TotalAreaUtilDe + ' m² a ' + product.UnidadesDeAte.TotalAreaUtilAte"
  
              [subtitle]="product.Produto"
              
              [bath]="product.UnidadesDeAte.TotalBanheiroDe === product.UnidadesDeAte.TotalBanheiroAte ? 
              product.UnidadesDeAte.TotalBanheiroDe : product.UnidadesDeAte.TotalBanheiroDe + ' a ' 
              + product.UnidadesDeAte.TotalBanheiroAte"
  
              [parking]="product.UnidadesDeAte.TotalVagaDe === product.UnidadesDeAte.TotalVagaAte ? 
                product.UnidadesDeAte.TotalVagaDe : product.UnidadesDeAte.TotalVagaDe + ' a ' 
                + product.UnidadesDeAte.TotalVagaAte"
              [title]="product.Endereco?.Bairro"
              [fase]="product.FaseProduto"
              ></app-box>
            </div>
          </ngx-slick-carousel>
          <div class="slick-prev"></div> 
          <div class="slick-next"></div>  
        </div>
      </div>

    </ng-container>

  </div>
</section>