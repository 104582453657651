import { Action, createReducer, on } from '@ngrx/store';

import * as ProductsActions from './products.actions';
import { Product } from '../../shared/interfaces/product.interface';
import { Status } from '../../shared/interfaces/state.interface';

export const productsFeatureKey = 'products';

export interface ProductState {
  products: Product[];
  states: string[];
  cities: string[];
  bairros: string[];
  prices: { max: number, min: number };
  sizes: { max: number, min: number };
  status: Status;
}

export interface ProductStore {
  readonly [productsFeatureKey]: ProductState;
}

export const productsInitialState: ProductState = {
  status: Status.INIT,
  products: [],
  states: [],
  cities: [],
  bairros: [],
  prices: null,
  sizes: null,
};

const reducer = createReducer(
  productsInitialState,
  on(ProductsActions.getProducts, (state, action) => ({
    ...state,
    status: Status.IN_PROGRESS,
  })),
  on(ProductsActions.getProductsSuccess, (state,
                                          action) => ({
    ...state,
    status: Status.INIT,
    products: action.products
      .map(product => {
        return {...product, FotoCapa: product.Fotos.find(foto => foto.CapaFoto === '1') || product.Fotos[0]};
      })
      .sort((p1, p2) => new Date(p2.DataAtualizacao).getTime() - new Date(p1.DataAtualizacao).getTime()),
    states: action.products.map(product => product.Endereco.Estado)
      .filter((elem, pos, array) => array.indexOf(elem) === pos),
    cities: action.products.map(product => product.Endereco.Cidade)
      .filter((elem, pos, array) => array.indexOf(elem) === pos),
    bairros: action.products.map(product => product.Endereco.Bairro)
      .filter((elem, pos, array) => array.indexOf(elem) === pos),
    prices: {
      min: action.products.reduce((min, p) =>
        Number(p.ValorDe) < min ? Number(p.ValorDe) : min, Number(action.products[0].ValorDe)),
      max: action.products.reduce((max, p) =>
        Number(p.ValorAte) > max ? Number(p.ValorAte) : max, Number(action.products[0].ValorAte)),
    },
    sizes: {
      min: action.products.reduce((min, p) =>
        Number(p.AreaUtilDe) < min ? Number(p.AreaUtilDe) : min, Number(action.products[0].AreaUtilDe)),
      max: action.products.reduce((max, p) =>
        Number(p.AreaUtilAte) > max ? Number(p.AreaUtilAte) : max, Number(action.products[0].AreaUtilAte)),
    },
  })),
  on(ProductsActions.getProductsFail, (state, action) => ({
    ...productsInitialState,
  })),
);

export function productsReducer(state: ProductState | undefined, action: Action): ProductState {
  return reducer(state, action);
}
