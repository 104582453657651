import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { companiesQuery } from './companies.selectors';
import { CompaniesState } from './companies.reducer';
import * as CompaniesActions from './companies.actions';

@Injectable()
export class CompaniesFacade {
  company$ = this.store.select(companiesQuery.getCompany);

  constructor(private store: Store<CompaniesState>) {
  }

  getCompany(subdomain: string): void {
    this.store.dispatch(CompaniesActions.getCompany({subdomain}));
  }
}
