import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AuthState } from './auth.reducer';
import { authQuery } from './auth.selectors';
import * as AuthActions from './auth.actions';
import { LoginUser, NewUser, ResetPassword, User } from '../../shared/interfaces/user.interface';

@Injectable()
export class AuthFacade {
  auht$ = this.store.select(authQuery.getAuth);
  user$ = this.store.select(authQuery.getUser);
  isLoggedIn$ = this.store.select(authQuery.getLoggedIn);

  constructor(private store: Store<AuthState>) {
  }

  login(loginUser: LoginUser): void {
    this.store.dispatch(AuthActions.login({loginUser}));
  }

  logout(): void {
    this.store.dispatch(AuthActions.logout());
  }

  forgotPassword(email: string): void {
    this.store.dispatch(AuthActions.forgotPassword({email}));
  }

  resetPassword(resetPassword: ResetPassword): void {
    this.store.dispatch(AuthActions.resetPassword({resetPassword}));
  }

  register(newUser: NewUser): void {
    this.store.dispatch(AuthActions.register({newUser}));
  }

  update(user: User): void {    
    this.store.dispatch(AuthActions.update({user}));
  }

  user(): void {
    this.store.dispatch(AuthActions.getUser());
  }
}
