import { Action, createReducer, on } from '@ngrx/store';
import * as AuthActions from './auth.actions';
import { User } from '../../shared/interfaces/user.interface';
import { Status } from '../../shared/interfaces/state.interface';

export const authFeatureKey = 'auth';

export interface Auth {
  loggedIn: boolean;
  user: User;
  status: Status;
}

export interface AuthState {
  readonly [authFeatureKey]: Auth;
}

export const authInitialState: Auth = {
  loggedIn: false,
  status: Status.INIT,
  user: {
    id: null,
    username: '',
    email: '',
    perfil: '',
    token: ''
  },
};

const reducer = createReducer(
  authInitialState,
  on(AuthActions.getUserSuccess, (state, action) => ({
    ...state,
    loggedIn: true,
    user: action.user,
  })),
  on(AuthActions.getUserFail, (state, action) => ({
    ...authInitialState,
  })),
  on(AuthActions.login, AuthActions.register, AuthActions.update, (state, _) => ({
    ...state,
    status: Status.IN_PROGRESS,
  })),
  on(AuthActions.registerSuccess, AuthActions.loginSuccess, AuthActions.updateSuccess, (state, action) => ({
    ...state,
    loggedIn: true,
    status: Status.INIT,
    user: action.user,
  })),
  on(AuthActions.registerFail, AuthActions.loginFail, AuthActions.forgotPassword, AuthActions.updateFail,
    AuthActions.resetPassword, (state, _) => ({
      ...state,
      status: Status.INIT,
    })),
  on(AuthActions.logout, (state, action) => ({
    ...authInitialState,
  })),
);

export function authReducer(state: Auth | undefined, action: Action): Auth {
  return reducer(state, action);
}
