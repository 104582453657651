import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { RegistrationService } from '../registration.service';
import { District, Region, User } from '../../shared/interfaces/user.interface';
import { ModalsService } from '../../components/modals/modals.service';
import { AuthFacade } from '../+state/auth.facade';
import { ProductsService } from '../../products/products.service';

@Component({
  selector: 'app-acting',
  templateUrl: './acting.component.html',
  styleUrls: ['./acting.component.scss']
})
export class ActingComponent implements OnInit {

  regions$: Observable<Region[]>;
  districts$: Observable<District[]>;
  user: User;
  newDistricts: District[] = [];
  regionsFromCRM: any[] = [];
  districtsFromCRM: any[] = [];
  arr_ids_districts: any[] = [];

  constructor(
    private authFacade: AuthFacade,
    private registrationService: RegistrationService,
    private router: Router,
    private modals: ModalsService,
    private productService: ProductsService) {
  }

  ngOnInit(): void {

    let url = window.location.host;
    let urlSplit = url.split('.');
    let company = urlSplit[0];

    this.authFacade.user$.subscribe(user => this.user = user);

    this.productService.getRegionFromCRM(company).then(regions => {
      for (let index = 0; index < regions['Regioes'].length; index++) {
        const element = regions['Regioes'][index];
        this.regionsFromCRM.push(element);
      } 
    });

    this.productService.getDistrictFromCRM(company).then(districts => {
      for (let index = 0; index < districts['Bairros'].length; index++) {
        const element = districts['Bairros'][index];
        this.districtsFromCRM.push(element);
      } 
    });

    this.regions$ = this.registrationService.getRegions();
    this.districts$ = this.registrationService.getDistricts();
  }

  toggleAccordion(event, index): void {
    const element = event.target;
    event.stopPropagation();
    element.classList.toggle('active');
    const panel = element.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + 'px';
    }
  }

  addDistrict(newDistrict: District): void {
    this.newDistricts = this.newDistricts?.find(district => district.id === newDistrict.id) ?
      this.newDistricts?.filter(district => district.id !== newDistrict.id) : [...this.newDistricts, newDistrict];
    
    if(this.arr_ids_districts.includes(newDistrict["id_bairro"])){
      var index = this.arr_ids_districts.indexOf(newDistrict["id_bairro"]);
      if (index !== -1) {
        this.arr_ids_districts.splice(index, 1);
      }
    }else{
      this.arr_ids_districts.push(newDistrict["id_bairro"]);
    }
  }

  avancar(): void {
    
    var string_ids_districts = null;
    string_ids_districts = this.arr_ids_districts.join(",");
    
    this.authFacade.update({...this.user, districts: this.newDistricts, ids_districts: string_ids_districts});
  }
}
