<style>

  .form-brokers, #select-coordenador-div{
    max-height: 200px;
    overflow: auto;
    text-align:justify;
  }
  
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #bcd65e;
  }

  .brokers{
    display: flex;
    height: auto;
    margin: 10px 0;
    cursor: pointer;
    align-items: center;
  }

  .brokers:hover{
    border-left: 5px solid rgba(188,214,94,1); 
    background: hex(188,214,94); 
    background: linear-gradient(90deg, rgba(188,214,94,0.5718662464985995) 0%, rgba(188,214,94,0.4009978991596639) 46%, rgba(255,255,255,1) 100%);
  }

  .broker-picture img{
    width: 60px;
    border-radius: 50%;
    margin: 0 20px;
  }

  .broker-content > h3, .broker-content > h4, .broker-content > h5{
    margin: 0;
    font-size: 12px;
  }

  .none{
    display: none;
  }

  .coordinator-selector{
    border-left: 5px solid rgba(188,214,94,1);
  }

  .center {
    margin: 2px;
    padding: 10px;
  }

  .politica-privacidade-error{
    font-size: 12px;
    color: #E91A1A;
    margin: 10px 0;
  }

  .tem-coordenador-error{
    font-size: 12px;
    color: #E91A1A;
    margin-left: 10px;
  }

</style>

<div class="register">
  <div class="register__modal">
    <app-company-logo [class]="'logo'" [link]="''"></app-company-logo>

    <h1>Informe seus dados:</h1>

    <form [formGroup]="autonomosForm" (ngSubmit)="onSubmit()" class="form__default login__form">

      <div class="form-group">
        <label [ngClass]="{ 'is-invalid-label': submitted && f.nome.errors }">Nome</label>
        <input type="text" formControlName="nome" [ngClass]="{ 'is-invalid': submitted && f.nome.errors }">
        <div *ngIf="submitted && f.nome.errors" class="invalid-feedback">
          <div *ngIf="f.nome.errors.required">Campo Obrigatório</div>
        </div>
      </div>

      <div class="form-group">
        <label [ngClass]="{ 'is-invalid-label': submitted && f.email.errors }">E-mail</label>
        <input type="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
               placeholder="Informe um e-mail válido" required>
        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
          <div *ngIf="f.email.errors.required">Campo Obrigatório</div>
          <div *ngIf="f.email.errors.pattern">Necessário email válido</div>
        </div>
      </div>

      <div class="form-group">
        <label [ngClass]="{ 'is-invalid-label': submitted && f.telefone.errors }">Telefone</label>
        <input type="text" formControlName="telefone" mask="(00) 00000-0000"
               [ngClass]="{ 'is-invalid': submitted && f.telefone.errors }" placeholder="(XX) XXXXX-XXXX">
        <div *ngIf="submitted && f.telefone.errors" class="invalid-feedback">
          <div *ngIf="f.telefone.errors.required">Campo Obrigatório</div>
        </div>
      </div>

      <div class="form-group">
        <label [ngClass]="{ 'is-invalid-label': submitted && f.cpf.errors }">CPF</label>
        <input type="text" formControlName="cpf" mask="000.000.000-00"
               [ngClass]="{ 'is-invalid': submitted && f.cpf.errors }" placeholder="">
        <div *ngIf="submitted && f.cpf.errors" class="invalid-feedback">
          <div *ngIf="f.cpf.errors.required">Campo Obrigatório</div>
          <div *ngIf="f.cpf.errors.cpfvalidator && (f.cpf.dirty || f.cpf.touched)">CPF Inválido</div>
        </div>
      </div>

      <div class="form-group">
        <label [ngClass]="{ 'is-invalid-label': submitted && f.creci.errors }">CRECI</label>
        <input type="text" formControlName="creci" [ngClass]="{ 'is-invalid': submitted && f.creci.errors }"
               placeholder="">
        <div *ngIf="submitted && f.creci.errors" class="invalid-feedback">
          <div *ngIf="f.creci.errors.required">Campo Obrigatório</div>
        </div>
      </div>

      <div class="form-group" *ngIf="coordenadoresAtivo">
        <p style="color: #2B2D2C;">Você possui um coordenador?</p>
          <div style="display: flex;align-items: flex-start;">
              <input type="radio" id="conhece_coordenador_nao" name="conhece_coordenador" value="0" onclick="document.getElementById('select-coordenador-div').classList.add('none');" (click)="removeClass()" checked>
              <label for="conhece_coordenador_nao">Não</label>
      
              <input type="radio" id="conhece_coordenador_sim" name="conhece_coordenador" value="1" onclick="document.getElementById('select-coordenador-div').classList.remove('none');">
              <label for="conhece_coordenador_sim">Sim</label>
              <span class="tem-coordenador-error none" id="tem-coordenador-error">Por favor, selecione um coordenador</span>
          </div>
  
          <div class="form-group form-brokers" id="select-coordenador-div" class="none">
            <ng-container *ngFor="let coordenador of coordenadores">
              
              <ng-container *ngIf="parametros[0]['dados_coordenador_portal'] == 'Paramêtro não encontrado.'">
  
                <div class="brokers" id="select-coordenador-{{coordenador['id_usuario']}}" (click)="removeClass(coordenador['id_usuario'])">
                  <div class="broker-picture" *ngIf="coordenador['foto']">
                    <img src="{{parametros[0]['url_amazon']}}{{parametros[0]['alias_amazon']}}/usuarios/{{coordenador['foto']}}">
                  </div>
                  <div class="broker-picture" *ngIf="!coordenador['foto']">
                    <img src="/assets/images/coordinator-default.jpg">
                  </div>
                  <div class="broker-content center" style="word-break: break-all;">
                    <h3>{{coordenador['nome']}}</h3>
                  </div>
                </div>
  
              </ng-container>
  
              <ng-container *ngIf="parametros[0]['dados_coordenador_portal'] == 1">
                
                <div class="brokers" id="select-coordenador-{{coordenador['id_usuario']}}" (click)="removeClass(coordenador['id_usuario'])">
                  <div class="broker-picture" *ngIf="coordenador['foto']">
                    <img src="{{parametros[0]['url_amazon']}}{{parametros[0]['alias_amazon']}}/usuarios/{{coordenador['foto']}}">
                  </div>
                  <div class="broker-picture" *ngIf="!coordenador['foto']">
                    <img src="/assets/images/coordinator-default.jpg">
                  </div>
                  <div class="broker-content center" style="word-break: break-all;">
                    <h3>{{coordenador['nome']}}</h3>
                    <h4>{{coordenador['email']}}</h4>
                  </div>
                </div>
                
              </ng-container>
  
              <ng-container *ngIf="parametros[0]['dados_coordenador_portal'] == 2">
                
                <div class="brokers" id="select-coordenador-{{coordenador['id_usuario']}}" (click)="removeClass(coordenador['id_usuario'])">
                  <div class="broker-picture" *ngIf="coordenador['foto']">
                    <img src="{{parametros[0]['url_amazon']}}{{parametros[0]['alias_amazon']}}/usuarios/{{coordenador['foto']}}">
                  </div>
                  <div class="broker-picture" *ngIf="!coordenador['foto']">
                    <img src="/assets/images/coordinator-default.jpg">
                  </div>
                  <div class="broker-content center" style="word-break: break-all;">
                    <h3>{{coordenador['nome']}}</h3>
                    <h5>{{coordenador['telefone']}}</h5>
                  </div>
                </div>
  
              </ng-container>
  
              <ng-container *ngIf="parametros[0]['dados_coordenador_portal'] == 3">
                
                <div class="brokers" id="select-coordenador-{{coordenador['id_usuario']}}" (click)="removeClass(coordenador['id_usuario'])">
                  <div class="broker-picture" *ngIf="coordenador['foto']">
                    <img src="{{parametros[0]['url_amazon']}}{{parametros[0]['alias_amazon']}}/usuarios/{{coordenador['foto']}}">
                  </div>
                  <div class="broker-picture" *ngIf="!coordenador['foto']">
                    <img src="/assets/images/coordinator-default.jpg">
                  </div>
                  <div class="broker-content center" style="word-break: break-all;">
                    <h3>{{coordenador['nome']}}</h3>
                    <h4>{{coordenador['email']}}</h4>
                    <h5>{{coordenador['telefone']}}</h5>
                  </div>
                </div>
  
              </ng-container>
  
            </ng-container>
          </div>
      </div>
  
      <ng-container *ngIf="parametros[0]['politica_de_privacidade_cliente'] == 1">
        <div class="form-group" style="margin-bottom: 0;">
          <div class="politica-privacidade-error none" id="politica-privacidade-error">Obrigatório concordar com nossa Política de Privacidade.</div>
          <input type="hidden" value="{{parametros[0]['politica_de_privacidade_cliente_obrigatoriedade']}}" id="politica_de_privacidade_cliente_obrigatoriedade_{{parametros[0]['politica_de_privacidade_cliente_obrigatoriedade']}}">
          <label style="width:100%; font-size:14px; color:#2B2D2C; margin-bottom:0;">
  
              <input type="checkbox" formcontrolname="politica_privacidade" class="ng-untouched ng-pristine ng-valid" id="politica_privacidade" (click)="changePoliticaValue()" onclick="document.getElementById('politica-privacidade-error').classList.add('none')">
  
              <ng-container *ngIf="parametros[0]['politica_de_privacidade_cliente_texto'] !== 'Paramêtro não encontrado.'">
                {{parametros[0]['politica_de_privacidade_cliente_texto']}}
              </ng-container>
  
              <ng-container *ngIf="parametros[0]['politica_de_privacidade_cliente_texto'] == 'Paramêtro não encontrado.'">
                Li e concordo com a política de privacidade.
              </ng-container>
              
              <ng-container *ngIf="parametros[0]['politica_de_privacidade_cliente_link'] !== 'Paramêtro não encontrado.'">
  
                <ng-container *ngIf="parametros[0]['politica_de_privacidade_cliente_link_texto'] !== 'Paramêtro não encontrado.'">
  
                  <p style="width:100%; text-align: center; padding: 5px;">
                    <a href="{{parametros[0]['politica_de_privacidade_cliente_link']}}" target="_blank" style="font-size: 12px;">{{parametros[0]['politica_de_privacidade_cliente_link_texto']}}</a>
                  </p>
  
                </ng-container>
  
                <ng-container *ngIf="parametros[0]['politica_de_privacidade_cliente_link_texto'] == 'Paramêtro não encontrado.'">
  
                  <p style="width:100%; text-align: center; padding: 5px;">
                    <a href="{{parametros[0]['politica_de_privacidade_cliente_link']}}" target="_blank" style="font-size: 12px;">{{parametros[0]['politica_de_privacidade_cliente_link']}}</a>
                  </p>
                  
                </ng-container>
  
              </ng-container>
              
          </label>
        </div>
      </ng-container>

      <button class="btn_solid--blocked--primary" type="submit">Avançar</button>

    </form>

    <app-button
      routerLink="/cadastro/selecionar"
      [label]="'Voltar'"
      [btnstyle]="'btn_solid--blocked--white'"></app-button>

    <app-register-pagination [dots]="4" [active]="2"></app-register-pagination>
  </div>
</div>
