<section class="login" style="padding: 100px 0;">

	<div class="login__modal">
    <app-company-logo [class]="'logo'" [link]="''"></app-company-logo>

		<form class="form__default login__form">

      <div class="form-group">
        <label>Usuário</label>
        <input type="text" name="username" required>
      </div>

      <div class="form-group">
        <label>Senha</label>
        <input type="text" name="username" required>
      </div>

      <a class="link__primary--small" href="#">Esqueci minha senha</a>

      <app-button
        [label]="'Entrar'"
        [btnstyle]="'btn_solid--blocked--primary-black'"></app-button>

      <app-button
        [label]="'Ainda não tem uma conta? cadastre-se.'"
        [btnstyle]="'btn_solid--blocked--dark'"></app-button>
    </form>

	</div>
</section>
