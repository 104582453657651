import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared.module';
import { NgxMaskModule, IConfig } from 'ngx-mask';

import { RegistrationRoutingModule } from './registration-routing.module';
import { SelectComponent } from './select/select.component';
import { ForgetComponent } from './forget/forget.component';
import { AgencyComponent } from './agency/agency.component';
import { AutonomusComponent } from './autonomus/autonomus.component';
import { RegisterPasswordComponent } from './register-password/register-password.component';
import { ActingComponent } from './acting/acting.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { authFeatureKey, authInitialState, authReducer } from './+state/auth.reducer';
import { AuthEffects } from './+state/auth.effects';
import { AuthFacade } from './+state/auth.facade';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null; //ngxMask

@NgModule({
  declarations: [
    SelectComponent,
    ForgetComponent,
    AgencyComponent,
    AutonomusComponent,
    RegisterPasswordComponent,
    ActingComponent,
    ResetPasswordComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RegistrationRoutingModule,
    NgxMaskModule.forRoot(),
    SharedModule,
    StoreModule.forFeature(authFeatureKey, authReducer, {initialState: authInitialState}),
    EffectsModule.forFeature([AuthEffects])
  ],
  providers: [
    AuthFacade
  ]
})
export class RegistrationModule {
}
