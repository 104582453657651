import { createAction, props } from '@ngrx/store';
import { LoginUser, NewUser, ResetPassword, User } from '../../shared/interfaces/user.interface';

export const getUser = createAction('[auth] GET_USER');

export const getUserSuccess = createAction('[auth] GET_USER_SUCCESS', props<{ user: User }>());

export const getUserFail = createAction('[auth] GET_USER_FAIL', props<{ error: Error }>());

export const login = createAction('[auth] LOGIN', props<{ loginUser: LoginUser }>());

export const loginSuccess = createAction('[auth] LOGIN_SUCCESS', props<{ user: User }>());

export const loginFail = createAction('[auth] LOGIN_FAIL');

export const register = createAction('[auth] REGISTER', props<{ newUser: NewUser }>());

export const registerSuccess = createAction('[auth] REGISTER_SUCCESS', props<{ user: User }>());

export const registerFail = createAction('[auth] REGISTER_FAIL');

export const update = createAction('[auth] UPDATE', props<{ user: User }>());

export const updateSuccess = createAction('[auth] UPDATE_SUCCESS', props<{ user: User }>());

export const updateFail = createAction('[auth] UPDATE_FAIL');

export const logout = createAction('[auth] LOGOUT');

export const forgotPassword = createAction('[auth] FORGOT PASSWORD', props<{ email: string }>());

export const resetPassword = createAction('[auth] RESET PASSWORD', props<{ resetPassword: ResetPassword }>());
