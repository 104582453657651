import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SelectComponent } from './select/select.component';
import { ForgetComponent } from './forget/forget.component';
import { AgencyComponent } from './agency/agency.component';
import { AutonomusComponent } from './autonomus/autonomus.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RegisterPasswordComponent } from './register-password/register-password.component';
import { ActingComponent } from './acting/acting.component';

const routes: Routes = [
    {
        path: 'cadastro/selecionar',
        component: SelectComponent,
        data: { showHeader: false, showFooter: false, num: 1 }
    },
    {
        path: 'cadastro/recupera-senha',
        component: ForgetComponent,
        data: { showHeader: false, showFooter: false, }
    },
    {
      path: 'cadastro/redefinir-senha',
      component: ResetPasswordComponent,
      data: { showHeader: false, showFooter: false, }
  },
    {
        path: 'cadastro/imobiliaria',
        component: AgencyComponent,
        data: { showHeader: false, showFooter: false, num: 2 }
    },
    {
        path: 'cadastro/autonomo',
        component: AutonomusComponent,
        data: { showHeader: false, showFooter: false, num: 2 }
    },
    {
        path: 'cadastro/registra-senha',
        component: RegisterPasswordComponent,
        data: { showHeader: false, showFooter: false, num: 3 }
    },
    {
        path: 'cadastro/area-atuacao',
        component: ActingComponent,
        data: { showHeader: false, showFooter: false, num: 4 }
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class RegistrationRoutingModule { }
