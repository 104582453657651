import { Injectable } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor() {
  }
  
  open(): void {
    Swal.fire({
        html: 'Aguarde enquanto enviamos o email',
        width: 450,
        imageHeight: 204,
        customClass: {
          popup: 'modal__forgot__password'
        },
        allowOutsideClick: false,
        onBeforeOpen: () => {
            Swal.showLoading()
        },
    });
  }

  close(): void {
    Swal.close();
  }
}
