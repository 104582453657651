import { Component, OnInit } from '@angular/core';
// import { ElementConfig, JpPreloadService } from '@jaspero/ng-image-preload';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ModalsService } from './modals.service';

@Component({
  selector: 'app-modals',
  templateUrl: './modals.component.html',
  styleUrls: ['./modals.component.scss']
})
export class ModalsComponent implements OnInit {

  hideModal = {
    popup: 'swal2-hide', backdrop: 'swal2-hide-bg'
  };
  showModal = {
    popup: 'swal2-hide', backdrop: 'swal2-hide-bg'
  };

  // config = {
  //   src : '/assets/images/ilustra_exit.svg',
  //   background: false
  // };

  constructor(private modalService: ModalsService) {}

  ngOnInit(): void {
  }


  modalExit(){
    Swal.fire({
      html: 'Você esta prestes a sair de sua conta. Tem certeza que deseja realizar esta ação?',
      width: 450,
      imageUrl: '/assets/images/ilustra_exit.svg',
      imageHeight: 227,
      showCancelButton: true,
      confirmButtonText: 'Sair',
      cancelButtonText: 'Cancelar',
      customClass: {
        popup: 'modal__exit',
        confirmButton: 'modal__btn--primary mb15',
        cancelButton: 'modal__btn--white'
      },
      buttonsStyling: false
    });
  }

  modalSend(){
    Swal.fire({
      html: 'Mensagem Enviada',
      width: 450,
      imageHeight: 183,
      imageUrl: '/assets/images/ilustra_send.svg',
      confirmButtonText: 'Ok, Entendi',
      customClass: {
        popup: 'modal__send',
        confirmButton: 'modal__btn--primary'
      },
      buttonsStyling: false
    });
  }

  modalWrongPassword(){
    this.modalService.modalError('Usuário não encontrado ou senha incorreta.');
  }

  modalPasswordSaved(){
    Swal.fire({
      html: 'Senha alterada com sucesso!',
      width: 450,
      imageHeight: 204,
      imageUrl: '/assets/images/ilustra_pwd_changed.svg',
      confirmButtonText: 'Ok, Entendi',
      customClass: {
        popup: 'modal__passwordsaved',
        confirmButton: 'modal__btn--primary',
      },
      buttonsStyling: false
    });
  }

  modalBlock(){
    Swal.fire({
      html: 'Para editar este dado<br>fale com seu coordenador.',
      width: 450,
      imageHeight: 205,
      imageUrl: '/assets/images/ilustra_block.svg',
      showCancelButton: true,
      confirmButtonText: 'Falar com cordenador',
      cancelButtonText: 'Voltar',
      customClass: {
        popup: 'modal__block',
        confirmButton: 'modal__btn--primary mb15',
        cancelButton: 'modal__btn--white'
      },
      buttonsStyling: false,
      didRender(){
        //console.log('rendered')
      },
      didOpen(){
        //console.log('did open')
      }
    });
  }
  // container: 'modal__container',

}
