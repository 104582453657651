import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Product } from '../shared/interfaces/product.interface';
import { Parameter } from '../shared/interfaces/Parameters.interface';
import { Coordinator } from '../shared/interfaces/coordinator.interface';
import { options } from '../registration/registration.module';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  token: string;

  constructor(private httpClient: HttpClient) {

  }

  auth(company): Observable<{ token: string }> {

    let login = '';
    let password = '';

    let url = '';

    if(company === 'ryazbek'){
     login = 'adm';
     password = environment.password_ryazbek;
     url = `https://${company}-parceria.hypnobox.com.br/api`;
    }
    else if(company === 'gamaro' || company === 'fibra'){
      login = 'fibra1parcerias';
      password = environment.password_fibra;
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    }
    else if(company === 'curyconstrutora'){
      login = 'curyspparcerias';
      password = environment.password_cury;
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    }
    else if(company === 'curyconstrutorarj'){
      login = 'curyrj_parcerias';
      password = environment.password_curyrj;
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    }
    else if(company === 'skr'){
      login = 'skrparcerias';
      password = environment.password_skr;
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    }
    else if(company === 'sequoia'){
      login = 'adm_sequoia_parcerias';
      password = environment.password_sequoia;
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    }
    else if(company === 'exto'){
      login = 'extoparcerias';
      password = environment.password_exto;
      url = `https://${company}parcerias.hypnobox.com.br/api`;
    }
    else if(company === 'hypnoboxparcerias' || company === 'localhost:4200'){
      login = 'parcerias';
      password = environment.password_parcerias;
      url = `https://parcerias.hypnobox.com.br/api`;
    }
    else if(company === 'start'){
      login = 'parcerias';
      password = environment.password_start;
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    }
    else if(company === 'mitre'){
      login = 'adm_mitre_parcerias';
      password = environment.password_mitre;
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    }
    else if(company === 'alfa'){
      login = 'parcerias';
      password = environment.password_alfa;
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    }
    else if(company === 'steel'){
      login = 'parcerias';
      password = environment.password_steel;
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    }
    else if(company === 'aam'){
      login = 'aamparcerias';
      password = environment.password_aam;
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    }
    else if(company === 'paesegregori'){
      login = 'paesegregoriparcerias';
      password = environment.password_paesegregori;
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    }
    else if(company === 'vendascanopus'){
      login = 'adm_canopus_parcerias';
      password = environment.password_canopus;
      url = `https://canopus-parcerias.hypnobox.com.br/api`;
    }
    else if(company === 'fraiha'){
      login = 'fraihaparcerias';
      password = environment.password_fraiha;
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    }
    else if(company === 'cs'){
      login = 'adm_cs_parcerias';
      password = environment.password_cs;
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    }
        else if(company === 'wvmaldi'){
      login = 'parcerias';
      password = environment.password_wvmaldi;
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    }
    
        else if(company === 'altti'){
      login = 'alttiparcerias';
      password = environment.password_altti;
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    }
    
    
        else if(company === 'planik'){
      login = 'parcerias';
      password = environment.password_planik;
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    }
    
    
        else if(company === 'brasilincorp'){
      login = 'parcerias';
      password = environment.password_brasilincorp;
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    }

    //console.log(url + '/auth?login='+login+'&password='+password+'&returnType=json');
   
    return this.httpClient.get<{ token: string }>(  url +
      '/auth?login='+login+'&password='+password+'&returnType=json');
  }

  getProducts({company}): Observable<Product[]> {
   
    let url = '';

    //console.log(company);

    if(company === 'ryazbek')
      url = `https://${company}-parceria.hypnobox.com.br/api`;
    else if(company === 'gamaro' || company === 'fibra')  
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'curyconstrutora')  
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'curyconstrutorarj')  
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'zkf')  
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'sequoia')  
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'hypnoboxparcerias' || company === 'localhost:4200')
      url = `https://parcerias.hypnobox.com.br/api`;
    else if(company === 'exto')
        url = `https://${company}parcerias.hypnobox.com.br/api`;
    else if(company === 'start')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'mitre')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'alfa')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'steel')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'aam')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'paesegregori')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'vendascanopus')
        url = `https://canopus-parcerias.hypnobox.com.br/api`;
    else if(company === 'fraiha')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;        
    else if(company === 'cs')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;    
    else if(company === 'wvmaldi')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;    
     else if(company === 'altti')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;           
     else if(company === 'brasilincorp')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;       
     else if(company === 'planik')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;      


    return this.auth(company).pipe(
      switchMap(auth => this.httpClient.get<{ Produtos: Product[] }>( url +
        '/products?token=' + auth.token + '&returnType=json&enablePhoto=1').pipe(
        map(res => res.Produtos.filter(el=>el.Produto.toLowerCase() !== 'institucional'))
        )
      )
    );
  }

  async getParameters(company: string,parameter: string) {

    let url = '';
    
    if(company === 'ryazbek')
      url = `https://${company}-parceria.hypnobox.com.br/api`;
    else if(company === 'gamaro' || company === 'fibra')
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'curyconstrutora')
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'curyconstrutorarj')
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'zkf')
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'sequoia')
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'hypnoboxparcerias' || company === 'localhost:4200')
      url = `https://parcerias.hypnobox.com.br/api`;
    else if(company === 'exto')
        url = `https://${company}parcerias.hypnobox.com.br/api`;
    else if(company === 'start')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'mitre')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'alfa')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'steel')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'aam')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'paesegregori')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;   
    else if(company === 'vendascanopus')
        url = `https://canopus-parcerias.hypnobox.com.br/api`;   
    else if(company === 'fraiha')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;   
    else if(company === 'cs')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;   
    else if(company === 'wvmaldi')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;   
    else if(company === 'altti')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;   
    else if(company === 'planik')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;   
    else if(company === 'brasilincorp')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;   
    

    let x = await this.auth(company).toPromise();

    return await this.httpClient.get<any[]>(  url +'/parameters?token=' + x.token + '&returnType=json&parameters=' + parameter).toPromise();

  }

  async getCoordinator(company: string) {

    let url = '';
      
    if(company === 'ryazbek')
      url = `https://${company}-parceria.hypnobox.com.br/api`;
    else if(company === 'gamaro' || company === 'fibra')
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'curyconstrutora')
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'curyconstrutorarj')
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'zkf')
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'sequoia')
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'hypnoboxparcerias' || company === 'localhost:4200')
      url = `https://parcerias.hypnobox.com.br/api`;
    else if(company === 'exto')
        url = `https://${company}parcerias.hypnobox.com.br/api`;
    else if(company === 'start')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'mitre')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'alfa')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'steel')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'aam')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'paesegregori')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'vendascanopus')
        url = `https://canopus-parcerias.hypnobox.com.br/api`;   
    else if(company === 'fraiha')
        url = `https://${company}-parcerias.hypnobox.com.br/api`; 
    else if(company === 'cs')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;   
    else if(company === 'wvmaldi')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;   
    else if(company === 'altti')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;   
    else if(company === 'planik')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;  
    else if(company === 'brasilincorp')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;          
        
        
        
    let x = await this.auth(company).toPromise();

    return await this.httpClient.get<any[]>(  url +'/getusersinfo?token=' + x.token + '&returnType=json&profiles[]=4&fields=nome,email,id_usuario,foto,telefone').toPromise();
  }

  async getModule(company: string,module: string) {

    let url = '';
    
    if(company === 'ryazbek')
      url = `https://${company}-parceria.hypnobox.com.br/api`;
    else if(company === 'gamaro' || company === 'fibra')
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'curyconstrutora')
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'curyconstrutorarj')
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'zkf')
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'sequoia')
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'hypnoboxparcerias' || company === 'localhost:4200')
      url = `https://parcerias.hypnobox.com.br/api`;
    else if(company === 'exto')
        url = `https://${company}parcerias.hypnobox.com.br/api`;
    else if(company === 'start')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'mitre')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'alfa')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'steel')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'aam')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'paesegregori')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;   
    else if(company === 'vendascanopus')
        url = `https://canopus-parcerias.hypnobox.com.br/api`;
    else if(company === 'fraiha')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;   
    else if(company === 'cs')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;   
    else if(company === 'wvmaldi')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;   
    else if(company === 'altti')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;   
    else if(company === 'planik')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;   
    else if(company === 'brasilincorp')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;   



    let x = await this.auth(company).toPromise();

    return await this.httpClient.get<any[]>(  url +'/modules?token=' + x.token + '&returnType=json&modules=' + module).toPromise();

  }

  async getClientInfoByEmail(company: string,email: string) {

    let url = '';
    
    if(company === 'ryazbek')
      url = `https://${company}-parceria.hypnobox.com.br/api`;
    else if(company === 'gamaro' || company === 'fibra')
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'curyconstrutora')
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'curyconstrutorarj')
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'zkf')
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'sequoia')
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'hypnoboxparcerias' || company === 'localhost:4200')
      url = `https://parcerias.hypnobox.com.br/api`;
    else if(company === 'exto')
        url = `https://${company}parcerias.hypnobox.com.br/api`;
    else if(company === 'start')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'mitre')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'alfa')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'steel')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'aam')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'paesegregori')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'vendascanopus')
        url = `https://canopus-parcerias.hypnobox.com.br/api`;   
    else if(company === 'fraiha')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'cs')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;   
    else if(company === 'wvmaldi')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;   
    else if(company === 'altti')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;   
    else if(company === 'planik')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;   
    else if(company === 'brasilincorp')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;   



    let x = await this.auth(company).toPromise();

    return await this.httpClient.get<any[]>(  url +'/getclientbyemail?token=' + x.token + '&returnType=json&email_cliente=' + email).toPromise();

  }

  async getRegionFromCRM(company: string) {

    let url = '';
      
    if(company === 'ryazbek')
      url = `https://${company}-parceria.hypnobox.com.br/api`;
    else if(company === 'gamaro' || company === 'fibra')
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'curyconstrutora')
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'curyconstrutorarj')
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'zkf')
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'sequoia')
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'hypnoboxparcerias' || company === 'localhost:4200')
      url = `https://parcerias.hypnobox.com.br/api`;
    else if(company === 'exto')
        url = `https://${company}parcerias.hypnobox.com.br/api`;
    else if(company === 'start')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'mitre')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'alfa')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'steel')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'aam')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'paesegregori')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'vendascanopus')
        url = `https://canopus-parcerias.hypnobox.com.br/api`;
    else if(company === 'fraiha')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'cs')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;   
    else if(company === 'wvmaldi')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;   
    else if(company === 'altti')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;   
    else if(company === 'planik')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;   
    else if(company === 'brasilincorp')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;   




    let x = await this.auth(company).toPromise();

    return await this.httpClient.get<any[]>(  url +'/getRegions?token=' + x.token + '&returnType=json&profiles[]=4&fields=nome,email,id_usuario,foto,telefone').toPromise();
  }

  async getDistrictFromCRM(company: string) {

    let url = '';
      
    if(company === 'ryazbek')
      url = `https://${company}-parceria.hypnobox.com.br/api`;
    else if(company === 'gamaro' || company === 'fibra')
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'curyconstrutora')
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'curyconstrutorarj')
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'zkf')
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'sequoia')
      url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'hypnoboxparcerias' || company === 'localhost:4200')
      url = `https://parcerias.hypnobox.com.br/api`;
    else if(company === 'exto')
        url = `https://${company}parcerias.hypnobox.com.br/api`;
    else if(company === 'start')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'mitre')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'alfa')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'steel')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'aam')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;
    else if(company === 'paesegregori')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;   
    else if(company === 'vendascanopus')
        url = `https://canopus-parcerias.hypnobox.com.br/api`;
    else if(company === 'fraiha')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;   
    else if(company === 'cs')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;   
    else if(company === 'wvmaldi')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;   
    else if(company === 'altti')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;   
    else if(company === 'planik')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;   
    else if(company === 'brasilincorp')
        url = `https://${company}-parcerias.hypnobox.com.br/api`;   
                        
        
    let x = await this.auth(company).toPromise();

    return await this.httpClient.get<any[]>(  url +'/getDistricts?token=' + x.token + '&returnType=json&profiles[]=4&fields=nome,email,id_usuario,foto,telefone').toPromise();
  }
}

  
