<footer>
  <div class="container">
    <p>© {{year}} - HYPNOBOX - Todos os direitos reservados.</p>
    <ul class="footer">
        <li><a href="https://www.hypnobox.com.br/" target="_blank">Conheça a Hypnobox</a></li>
        <!--
            <li><a href="#">Política de privacidade</a></li>
            <li><a href="#">Termos de uso</a></li>
        -->
    </ul>
  </div>
</footer>
