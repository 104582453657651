<header>
  <div class="container">
    <app-company-logo [class]="'header__logo'" [link]="'/logado'"></app-company-logo>
    <nav class="menu__main">
      <div *ngIf="!isLoggedIn">
        <div class="menu__main--notlogged">
          <app-button routerLink="/cadastro/selecionar" [label]="'Cadastre-se'"
                      [btnstyle]="'btn_solid--dark'"></app-button>
          <app-button routerLink="/login" [label]="'Login'" [btnstyle]="'btn_solid--primary'"></app-button>
        </div>
      </div>

      <ul *ngIf="isLoggedIn">
        <li><a routerLink="/logado">Início</a></li>
        <li><a routerLink="/imoveis">Imóveis</a></li>
        <!-- <li><a routerLink="minha-conta/meus-dados">Meus dados</a></li> -->
        <li class="logged">
          <a routerLink="minha-conta/meus-dados">
            {{user.perfil === UserPerfil.AUTONOMO ? user.nome : user.razao_social}}
          </a>
          <ul class="submenu">
            <li><a routerLink="/minha-conta/meus-dados">Dados de cadastro</a></li>
            <li><a routerLink="/minha-conta/senha">Alterar Senha</a></li>
            <li><a routerLink="" (click)="logout()">Sair</a></li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>

  <a class="menu__hamburguer" (click)="toggleMenu()" [ngClass]="status ? 'menu_active' : ''">
    <span></span>
    <span></span>
    <span></span>
  </a>

  <nav class="menu__mobile" [ngClass]="status ? 'menu__mobile--active' : ''">

    <div *ngIf="!isLoggedIn" class="menu__mobile--contlogin">
      <h1>Seja Bem-Vindo(a) Ao Hypnobox Parcerias.</h1>
      <app-button routerLink="/login" [label]="'Login'" [btnstyle]="'btn_solid--primary'"></app-button>
      <app-button routerLink="/cadastro/selecionar" [label]="'Cadastre-se'" [btnstyle]="'btn_solid--dark'"></app-button>
    </div>

    <div *ngIf="isLoggedIn" class="menu__mobile--contlogged">

      <ul class="mb__menu">
        <li class="username">{{user.perfil === UserPerfil.AUTONOMO ? user.nome : user.razao_social}}</li>
        <li><a routerLink="/logado">Início</a></li>
        <li><a routerLink="/imoveis">Imóveis</a></li>
        <!-- <li><a routerLink="/ajuda">Falar com coordenador</a></li> -->
      </ul>

      <ul class="mb__logged">
        <li><a routerLink="/minha-conta/meus-dados">Dados de cadastro</a></li>
        <li><a routerLink="/minha-conta/senha">Alterar senha</a></li>
        <li><a routerLink="/" (click)="logout()">Sair</a></li>
      </ul>
    </div>
  </nav>
</header>
