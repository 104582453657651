import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { User } from '../../shared/interfaces/user.interface';
import { AuthFacade } from '../../registration/+state/auth.facade';
import { LocalStorageJwtService } from '../../shared/services/local-storage-jwt.service';
import { Product } from '../../shared/interfaces/product.interface';
import { ProductsFacade } from '../../products/+state/products.facade';
import { ProductsService } from '../../products/products.service';

@Component({
  selector: 'app-home-logged',
  templateUrl: './home-logged.component.html',
  styleUrls: ['./home-logged.component.scss']
})
export class HomeLoggedComponent implements OnInit {
  user$: Observable<User>;
  isLoggedIn$: Observable<boolean>;
  products$: Observable<Product[]>;
  acessoLiberado: boolean;

  slideConfig = {
    nextArrow: '.slick-next', 
    prevArrow: '.slick-prev',  
    dots: true,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private authFacade: AuthFacade,
    private localStorageJwtService: LocalStorageJwtService,
    private productsFacade: ProductsFacade,
    private productsService: ProductsService) {
  }

  ngOnInit(): void {
    let url = window.location.host;
    let urlSplit = url.split('.');
    let company = urlSplit[0];
    //this.acessoLiberado = false;

    this.productsService.getModule(company, 'acesso-portal-parceiros').then(x => {
      if(x['Modulos']['acesso-portal-parceiros'] == 1){

        this.user$ = this.authFacade.user$;
        this.user$.subscribe(
          user => {
            this.productsService.getClientInfoByEmail(company, user.email).then(y => {
              if(y['Cliente'] !== undefined){
                if(y['Cliente'][0]?.acesso_portal == 1){
                  this.acessoLiberado = true;
                }else{
                  this.acessoLiberado = false;
                }
              }else{
                this.acessoLiberado = false;
              }
            });
          }
        );

      }else{
        this.acessoLiberado = true;
      }
    });

    if(this.acessoLiberado === false) {
      // Não faz nada no momento mesmo, só impede de processar qualquer informação de produtos até o parceiro ter o acesso liberado pelo CRM de parcerias
    } else {
      this.products$ = this.productsFacade.products$;
      this.productsFacade.getProducts({company});
      this.user$ = this.authFacade.user$;
      this.isLoggedIn$ = this.authFacade.isLoggedIn$;
      this.localStorageJwtService
        .getItem()
        .pipe(
          take(1),
          filter(token => !!token),
        )
        .subscribe(token => this.authFacade.user());
    }
  }

}
