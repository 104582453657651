import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-register-pagination',
    templateUrl: './register-pagination.component.html',
    styleUrls: ['./register-pagination.component.scss']
})
export class RegisterPaginationComponent implements OnInit {

    @Input() dots: number;
    @Input() active: number;

    constructor() { }

    ngOnInit(): void {
    }

    numSequence(n: number): Array<number> {
        return Array(n);
    }

}
