import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StyleguideAppComponent } from './components/styleguide-app/styleguide-app.component';
import { StyleguideLoginComponent } from './components/styleguide-login/styleguide-login.component';
import { HomeComponent } from './views/home/home.component';
import { HomeLoggedComponent } from './views/home-logged/home-logged.component';
import { ProductDetailComponent } from './products/product-detail/product-detail.component';
import { ProductsComponent } from './products/products.component';
import { HelpComponent } from './views/help/help.component';
import { PasswordComponent } from './views/myaccount/password/password.component';
import { DataComponent } from './views/myaccount/data/data.component';
import { LoginComponent } from './views/myaccount/login/login.component';
import { ModalsComponent } from './components/modals/modals.component';
import { AuthGuardService } from './shared/services/auth-guard.service'; // TODO: remover esse módulo

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {showHeader: false, showFooter: false}
  },
  {
    path: 'logado',
    component: HomeLoggedComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'ajuda',
    component: HelpComponent
  },
  {
    path: 'minha-conta/senha',
    component: PasswordComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'minha-conta/meus-dados',
    component: DataComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'styleguide-login',
    component: StyleguideLoginComponent
  },
  {
    path: 'styleguide-app',
    component: StyleguideAppComponent
  },
  {
    path: 'modais',
    component: ModalsComponent,
    canActivate: [AuthGuardService],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
