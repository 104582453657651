<div class="register">
  <div class="register__modal">
    <app-company-logo [class]="'logo'" [link]="''"></app-company-logo>

    <h1>Crie uma nova senha de acesso:</h1>

    <form (ngSubmit)="onSubmit()" [formGroup]="passwordReset" class="form__default login__form">
      <div class="form-group">
        <label [ngClass]="{ 'is-invalid-label': submitted && f.password.errors }">Senha</label>
        <input [ngClass]="{ 'is-invalid': submitted && f.password.errors }" formControlName="password" required
               type="password">
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
          <div *ngIf="f.password.errors.required">Campo obrigatório</div>
          <div *ngIf="f.password.errors.minlength">Necessário pelo menos 8 dígitos</div>
        </div>
      </div>

      <div class="form-group">
        <label [ngClass]="{ 'is-invalid-label': submitted && f.passwordConfirmation.errors }">Confirmar Senha</label>
        <input type="password" formControlName="passwordConfirmation"
               [ngClass]="{ 'is-invalid': submitted && f.passwordConfirmation.errors }" required>
        <div *ngIf="submitted && f.passwordConfirmation.errors" class="invalid-feedback">
          <div *ngIf="f.passwordConfirmation.errors.required">Campo obrigatório</div>
          <div *ngIf="f.passwordConfirmation.errors.confirmedValidator">Senha não confirmada.</div>
        </div>
          </div>

          <p>A senha deve conter 8 caracteres sendo composta por letras e números. Pelos uma letra deve ser maiúscula.</p>
          <button class="btn_solid--blocked--primary" type="submit">Atualizar Senha</button>
      </form>
  </div>
</div>

