<div class="register">
    <div class="register__modal">
      <app-company-logo [class]="'logo'" [link]="''"></app-company-logo>
        <h1>Crie uma senha de acesso:</h1>

        <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()"  class="form__default login__form">
            <div class="form-group">
                <label [ngClass]="{ 'is-invalid-label': submitted && f.password.errors }">Senha</label>
                <input type="password" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" required>
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">Campo obrigatório</div>
                    <div *ngIf="f.password.errors.minlength">Necessário pelo menos 8 dígitos</div>
                </div>
            </div>

            <div class="form-group">
                <label [ngClass]="{ 'is-invalid-label': submitted && f.confirm_password.errors }">Confirmar Senha</label>
                <input type="password" formControlName="confirm_password" [ngClass]="{ 'is-invalid': submitted && f.confirm_password.errors }" required>
                <div *ngIf="submitted && f.confirm_password.errors" class="invalid-feedback">
                    <div *ngIf="f.confirm_password.errors.required">Campo obrigatório</div>
                    <!-- <div *ngIf="f.confirm_password.errors.password">Confirmar senha</div> -->
                    <div *ngIf="f.confirm_password.errors.confirmedValidator">Senha não confirmada.</div>
                </div>
            </div>

            <p>A senha deve conter 8 caracteres sendo composta por letras e números. Pelos uma letra deve ser maiúscula.</p>
            <button class="btn_solid--blocked--primary" type="submit">Avançar</button>
        </form>

        <app-button
            routerLink="{{registrationRouteByPerfil}}"
            [label]="'Voltar'"
            [btnstyle]="'btn_solid--blocked--white'"></app-button>

        <app-register-pagination [dots]="4" [active]="3"></app-register-pagination>
    </div>
</div>
