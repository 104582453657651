import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-img-product',
  templateUrl: './modal-img-product.component.html',
  styleUrls: ['./modal-img-product.component.scss']
})
export class ModalImgProductComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ModalImgProductComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }


}
