import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as ProductsActions from './products.actions';
import { ModalsService } from '../../components/modals/modals.service';
import { ProductsService } from '../products.service';


@Injectable()
export class ProductsEffects {
  getProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.getProducts),
      switchMap((company) =>
        this.productsService.getProducts(company).pipe(
          map(data => ProductsActions.getProductsSuccess({products: data})),
          catchError(error => of(ProductsActions.getProductsFail(error))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private modalsService: ModalsService,
    private productsService: ProductsService,
    private router: Router,
  ) {
  }
}
