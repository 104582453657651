import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-number-spinner',
  templateUrl: './number-spinner.component.html',
  styleUrls: ['./number-spinner.component.scss']
})
export class NumberSpinnerComponent {

  @Input() value = 1;
  @Input() min = 1;
  @Output() valueChange: EventEmitter<number>;

  constructor() {
    this.valueChange = new EventEmitter<number>(null);
  }

  increment(): void {
    this.value = this.value + 1;
    this.valueChange.emit(this.value);
  }

  decrement(): void {
    if (this.value > this.min) {
      this.value = this.value - 1;
      this.valueChange.emit(this.value);
    }
  }
}
