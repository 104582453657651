<div class="myaccount">
  <div class="container">
    <h1>Alterar Senha</h1>

    <!-- alterar para form, estava dando erro -->
    <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()" class="form__default login__form">

      <div class="form-group">
        <label [ngClass]="{ 'is-invalid-label': submitted && f.password.errors }">Criar senha</label>
        <input type="password" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
               required>
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
          <div *ngIf="f.password.errors.required">Campo Obrigatório</div>
        </div>
      </div>

      <div class="form-group">
        <label [ngClass]="{ 'is-invalid-label': submitted && f.confirm_password.errors }">Repetir Senha</label>
        <input type="password" formControlName="confirm_password"
               [ngClass]="{ 'is-invalid': submitted && f.confirm_password.errors }" required>
        <div *ngIf="submitted && f.confirm_password.errors" class="invalid-feedback">
          <div *ngIf="f.confirm_password.errors.required">Campo Obrigatório</div>
        </div>
      </div>

      <p>A senha deve conter 8 caracteres sendo composta por letras e números. Pelos uma letra deve ser maiúscula.</p>

      <app-button
        [label]="'Salvar'"
        [btnstyle]="'btn_solid--blocked--primary'"></app-button>

    </form>

  </div>
</div>
