import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { productsQuery } from './products.selectors';
import { ProductStore } from './products.reducer';
import * as ProductsActions from './products.actions';

@Injectable()
export class ProductsFacade {
  products$ = this.store.select(productsQuery.getProducts);
  states$ = this.store.select(productsQuery.getStates);
  cities$ = this.store.select(productsQuery.getCities);
  bairros$ = this.store.select(productsQuery.getBairros);
  prices$ = this.store.select(productsQuery.getPrices);
  sizes$ = this.store.select(productsQuery.getSizes);

  constructor(private store: Store<ProductStore>) {
  }

  getProducts(company): void {
    this.store.dispatch(ProductsActions.getProducts(company));
  }
}
