import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

    @Input() label: string;
    @Input() btnstyle: string;
    @Input() icon: string;
    // @Output() onClick = new EventEmitter<any>();

    constructor(private router: Router) { }

    ngOnInit(): void {
    }

}
