import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Options } from 'ng5-slider';
import { ProductsFacade } from './+state/products.facade';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Product } from '../shared/interfaces/product.interface';
import { Filter } from '../shared/interfaces/filter.interface';
import { filter, map, switchMap } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NumberSpinnerComponent } from '../components/template/ui/number-spinner/number-spinner.component';
import { ProductsService } from './products.service';
import { CompileSummaryKind } from '@angular/compiler';
import { AuthFacade } from '../registration/+state/auth.facade';
import { User } from '../shared/interfaces/user.interface';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit, AfterViewInit {

  @ViewChild('sidebar') sidebar: ElementRef;
  @ViewChild('arrowmenu') arrowmenu: ElementRef;
  @ViewChild('accheader') accheader: ElementRef;
  @ViewChild('estados') estados: NgSelectComponent;
  @ViewChild('cidades') cidades: NgSelectComponent;
  @ViewChild('dormitorios') dormitorios: NumberSpinnerComponent;
  @ViewChild('suites') suites: NumberSpinnerComponent;
  @ViewChild('banheiros') banheiros: NumberSpinnerComponent;
  @ViewChild('vagas') vagas: NumberSpinnerComponent;

  filters: BehaviorSubject<Filter>;
  products$: Observable<Product[]>;
  states$: Observable<string[]>;
  cities$: Observable<string[]>;
  bairros$: Observable<string[]>;
  prices$: Observable<{ max: number, min: number }>;
  sizes$: Observable<{ max: number, min: number }>;
  acessoLiberado: boolean;
  user$: Observable<User>;

  priceOptions: Options = {
    translate: (price: number): string => {
      return 'R$ ' + (price / 1000) + ' mil';
    }
  };
  sizeOptions: Options = {
    translate: (size: number): string => {
      return size + ' m²';
    }
  };

  constructor(
    private productsFacade: ProductsFacade, 
    private productsService: ProductsService,
    private authFacade: AuthFacade
  ) {
    this.filters = new BehaviorSubject<Filter>({
      Bairros: [],
      TiposProduto: [],
      Finalidades: [],
      FasesProduto: [],
    });

    combineLatest(this.productsFacade.sizes$, this.productsFacade.prices$)
      .pipe(filter(([sizes, prices]) => !!sizes && !!prices))
      .subscribe(
        ([sizes, prices]) => {
          this.sizeOptions = {...this.sizeOptions, ceil: sizes.max, floor: sizes.min};
          this.priceOptions = {...this.priceOptions, ceil: prices.max, floor: prices.min};
          this.filters.next(
            {
              ...this.filters.value,
              sizeOptions: {
                ceil: sizes.max,
                floor: sizes.min,
                max: sizes.max,
                min: sizes.min,
              },
              priceOptions: {
                ceil: prices.max,
                floor: prices.min,
                max: prices.max,
                min: prices.min,
                touched:false
              }
            });
        });
  }

  ngOnInit(): void {
    this.products$ = this.filters
      .pipe(
        switchMap(newFilters => {
          if (newFilters) {
            this.cities$ = this.productsFacade.products$.pipe(
              map(products => products
                .filter(product => product.Endereco.Estado === newFilters.Estado)
                .map(product => product.Endereco.Cidade)
                .filter((elem, pos, array) => array.indexOf(elem) === pos))
            );

            this.bairros$ = this.productsFacade.products$.pipe(
              map(products => products
                .filter(product => product.Endereco.Cidade === newFilters.Cidade)
                .map(product => product.Endereco.Bairro)
                .filter((elem, pos, array) =>
                  array.indexOf(elem) === pos))
            );
            return this.productsFacade.products$.pipe(
              map(products => products.filter(product => {
                const aplicaFiltros = this.aplicaFiltros(product.Unidades, newFilters, product);
                 if (aplicaFiltros) {
                  console.log(newFilters);
                }
                return aplicaFiltros;
              }))
          );
          } else {
            return this.productsFacade.products$;
          }
        })
      );

    let url = window.location.host;
    let urlSplit = url.split('.');
    let company = urlSplit[0];
    //this.acessoLiberado = false;

    this.productsService.getModule(company, 'acesso-portal-parceiros').then(x => {
      if(x['Modulos']['acesso-portal-parceiros'] == 1){

        this.user$ = this.authFacade.user$;
        this.user$.subscribe(
          user => {
            this.productsService.getClientInfoByEmail(company, user.email).then(y => {
              if(y['Cliente'] !== undefined){
                if(y['Cliente'][0]?.acesso_portal == 1){
                  this.acessoLiberado = true;
                }else{
                  this.acessoLiberado = false;
                }
              }else{
                this.acessoLiberado = false;
              }
            });
          }
        );

      }else{
        this.acessoLiberado = true;
      }
    });

    this.states$ = this.productsFacade.states$;
    this.productsFacade.getProducts({company});
  }

  filterProductsByFilter(newFilter: Filter): void {
    if (newFilter.Estado) {
      this.estados?.clearEvent.subscribe(
        () => this.cidades.clearModel()
      );
      newFilter.Cidade = null;
      newFilter.Bairro = null;
      newFilter.Bairros = [];
      this.cidades?.clearModel();
    }
    if (newFilter.Cidade) {
      newFilter.Bairro = null;
      newFilter.Bairros = [];
    }
    if (newFilter.Bairro) {
      newFilter.Bairros = this.filters?.value.Bairros?.find(bairro => bairro === newFilter.Bairro) ?
        this.filters?.value?.Bairros?.filter(bairro => bairro !== newFilter.Bairro) :
        [...this.filters?.value?.Bairros, newFilter.Bairro];
    }
    if (newFilter.TipoProduto) {
      newFilter.TiposProduto = this.filters?.value.TiposProduto?.find(tipoProduto => tipoProduto === newFilter.TipoProduto) ?
        this.filters?.value?.TiposProduto?.filter(tipoProduto => tipoProduto !== newFilter.TipoProduto) :
        [...this.filters?.value?.TiposProduto, newFilter.TipoProduto];
    }
    if (newFilter.Finalidade) {
      newFilter.Finalidades = this.filters?.value.Finalidades?.find(finalidade => finalidade === newFilter.Finalidade) ?
        this.filters?.value?.Finalidades?.filter(finalidade => finalidade !== newFilter.Finalidade) :
        [...this.filters?.value?.Finalidades, newFilter.Finalidade];
    }
    if (newFilter.FaseProduto) {
      newFilter.FasesProduto = this.filters?.value.FasesProduto?.find(faseProduto => faseProduto === newFilter.FaseProduto) ?
        this.filters?.value?.FasesProduto?.filter(faseProduto => faseProduto !== newFilter.FaseProduto) :
        [...this.filters?.value?.FasesProduto, newFilter.FaseProduto];
    }
    this.filters.next({...this.filters.value, ...newFilter});
  }

  ngAfterViewInit(): void {
    /*
    const fPrincipais: HTMLElement = document.getElementById('accordPrincipais') as HTMLElement;
    const fFiltrar: HTMLElement = document.getElementById('accordFiltrar') as HTMLElement;
    fPrincipais.click();
    fFiltrar.click();
    */
  }

  toggleAccordion(event, index): void {
    const element = event.target;
    event.stopPropagation();
    element.classList.toggle('active');
    const panel = element.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + 'px';
    }
  }

  // exibe filtros na versão mobile
  toggleFilters(): void {
    const sideBar = this.sidebar;
    const arrowMenu = this.arrowmenu;
    sideBar.nativeElement.classList.toggle('sidebar-visible');
    arrowMenu.nativeElement.classList.toggle('arrowmenu-active');
  }

  clearFilters(): void {
    this.filters.next({
      Bairros: [],
      TiposProduto: [],
      Finalidades: [],
      FasesProduto: [],
      priceOptions: {...this.priceOptions, max: this.priceOptions.ceil, min: this.priceOptions.floor, touched: false},
      sizeOptions: {...this.sizeOptions, max: this.sizeOptions.ceil, min: this.sizeOptions.floor}
    });
    this.estados?.clearModel();
    this.cidades?.clearModel();
    this.dormitorios.value = 0;
    this.suites.value = 0;
    this.banheiros.value = 0;
    this.vagas.value = 0;
    const checkboxes = document.getElementsByTagName('input');

    for (const i in checkboxes) {
      if (checkboxes[i].type === 'checkbox' && checkboxes[i].className === 'checkbox__input') {
        checkboxes[i].checked = false;
      }
    }
  }

  aplicaFiltros(Unidade: any, newFilters: any, product: any): boolean {
  
    return (
      this.filtrosDormitorios(Unidade, newFilters) &&
      this.filtrosSuites(Unidade, newFilters) &&
      this.filtrosBanheiros(Unidade, newFilters) &&
      this.filtrosVagas(Unidade, newFilters) &&
      this.filtrosEstados(product, newFilters) &&
      this.filtrosCidade(product, newFilters) &&
      this.filtrosBairros(product, newFilters) &&
      this.filtrosTipoProduto(product, newFilters) &&
      this.filtrosFinalidades(product, newFilters) &&
      this.filtrosFaseProduto(product, newFilters) &&
      this.filtrosSizeOptions(product, newFilters) &&
      this.filtrosPriceOptions(product, newFilters)
    );
  }
  
  filtrosDormitorios(unidades: any, newFilters: any): boolean {
    const totalDormitorioFiltro = unidades.some((unidade) => {
      return (
        unidade.TotalDormitorio == newFilters.Dormitorios
      );
    });
    
    return !newFilters?.Dormitorios||totalDormitorioFiltro
  }

  filtrosSuites(unidades: any, newFilters: any): boolean {
    const totalSuitesFiltro = unidades.some((unidade) => {
      return (
        unidade.TotalSuite == newFilters.Suites
      );
    });
    
    return !newFilters?.Suites||totalSuitesFiltro
  }
  
  filtrosBanheiros(unidades: any, newFilters: any): boolean {
    const totalBanheiroFiltro = unidades.some((unidade) => {
      return (
        unidade.TotalBanheiro == newFilters.Banheiros
      );
    });
    
    return !newFilters?.Banheiros||totalBanheiroFiltro
  }
  
  filtrosVagas(unidades: any, newFilters: any): boolean {
    const totalVagaFiltro = unidades.some((unidade) => {
      return (
        unidade.TotalVaga == newFilters.Vagas
      );
    });
      
    return !newFilters?.Vagas||totalVagaFiltro
  }

  filtrosEstados(product: any, newFilters: any): boolean {
    return !newFilters?.Estado || product.Endereco.Estado === newFilters.Estado;
  }
  
  filtrosCidade(product: any, newFilters: any): boolean {
    return !newFilters?.Cidade || product.Endereco.Cidade === newFilters.Cidade;
  }
  
  filtrosBairros(product: any, newFilters: any): boolean {
    return !newFilters?.Bairros.length || newFilters.Bairros.includes(product.Endereco.Bairro);
  }
  
  filtrosTipoProduto(product: any, newFilters: any): boolean {
    return !newFilters?.TiposProduto.length || newFilters.TiposProduto.includes(product.TipoProduto);
  }
  
  filtrosFinalidades(product: any, newFilters: any): boolean {
    return !newFilters?.Finalidades.length || newFilters.Finalidades.includes(product.Finalidade);
  }
  
  filtrosFaseProduto(product: any, newFilters: any): boolean {
    return !newFilters?.FasesProduto.length || newFilters.FasesProduto.includes(product.FaseProduto);
  }
  
  filtrosSizeOptions(product: any, newFilters: any): boolean {
    return !newFilters?.sizeOptions || (
      product.AreaUtilDe >= newFilters.sizeOptions.min &&
      product.AreaUtilAte <= newFilters.sizeOptions.max
    );
  }
  
  filtrosPriceOptions(product: any, newFilters: any): boolean {

   console.log(newFilters, product);
   
   return !newFilters?.priceOptions.touched || (
     product.ValorDe >= newFilters.priceOptions.min &&
     product.ValorAte <= newFilters.priceOptions.max
   );
  }
  
}
