<div class="register">
    <div class="register__modal--step" >

        <div class="register__infos">
          <app-company-logo [class]="'logo'" [link]="''"></app-company-logo>
            <h1>Você é imobiliária ou autônomo?</h1>
        </div>

        <ul class="register__select">
            <li>
                <a routerLink="/cadastro/imobiliaria">
                    <img src="assets/images/ilustra_agency.svg" alt="Ilustração Imobiliária">
                    <span>Imobiliária</span>
                </a>
            </li>
            <li>
                <a routerLink="/cadastro/autonomo">
                    <img src="assets/images/ilustra_realstator.svg" alt="Ilustração Autônomo">
                    <span>Autônomo</span>
                </a>
            </li>
        </ul>

        <app-register-pagination [dots]="4" [active]="1"></app-register-pagination>

    </div>
</div>
