import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthFacade } from '../../../registration/+state/auth.facade';
import { Observable } from 'rxjs';
import { User } from '../../../shared/interfaces/user.interface';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {

  passwordForm: FormGroup;
  submitted = false;
  user: User;

  constructor(
    private formBuilder: FormBuilder,
    private authFacade: AuthFacade) {
    authFacade.user$.subscribe(user => this.user = user);
  }

  ngOnInit(): void {
    this.passwordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirm_password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  get f(): { [key: string]: AbstractControl; } {
    return this.passwordForm.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.passwordForm.valid) {
      this.authFacade.update({
        ...this.user,
        ...this.passwordForm.value
      });
    }
  }

}
