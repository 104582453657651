<div class="box">
  <div class="box__tag">{{ fase }}</div>
  <a href="#" routerLink="{{ link }}" class="box__img">
    <img src="{{ image }}" alt="{{ title }}">
  </a>
  <div class="box__info">
      <span>{{ title }}</span>
      <span>{{ subtitle }}</span>
      <ul class="box__ul">
          <li class="area">{{ size || '0' }} m²</li>
          <li class="room">{{ room || '0' }} </li>
          <li class="bath">{{ bath || '0'}}</li>
          <li class="parking">{{ parking || '0' }}</li>
      </ul>
  </div>
  <a routerLink="{{ link }}" class="box__link">+ ver ficha completa</a>
</div>
