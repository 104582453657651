import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { routerSlideAnimation } from './registration/route-animations';
import { Observable } from 'rxjs';
import { User } from './shared/interfaces/user.interface';
import { AuthFacade } from './registration/+state/auth.facade';
import { filter, take } from 'rxjs/operators';
import { LocalStorageJwtService } from './shared/services/local-storage-jwt.service';
import { TenantService } from './shared/services/tenant.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routerSlideAnimation],
})

export class AppComponent implements OnInit {
  title = 'hypnobox-parcerias';
  showHeader = false;
  showFooter = false;
  user$: Observable<User>;
  isLoggedIn$: Observable<boolean>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authFacade: AuthFacade,
    private localStorageJwtService: LocalStorageJwtService,
    private router: Router,
    private tenantService: TenantService) {
    this.tenantService.getTenant();
  }

  ngOnInit(): void {
    this.user$ = this.authFacade.user$;
    this.isLoggedIn$ = this.authFacade.isLoggedIn$;
    this.localStorageJwtService
      .getItem()
      .pipe(
        take(1),
        filter(token => !!token),
      )
      .subscribe(() => {
        this.authFacade.user();
      });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.activatedRoute.firstChild.snapshot.routeConfig.path === '') {
          this.isLoggedIn$.subscribe(logged => logged && this.router.navigateByUrl('logado'));
        }
        this.showHeader = this.activatedRoute.firstChild.snapshot.data.showHeader !== false;
        this.showFooter = this.activatedRoute.firstChild.snapshot.data.showFooter !== false;
      }
    });
  }

  getRouteAnimation(outlet: RouterOutlet): number {
    return outlet.activatedRouteData.num === undefined
      ? -1
      : outlet.activatedRouteData.num;
  }
}
