import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Company } from '../../../../shared/interfaces/company.interface';
import { environment } from '../../../../../environments/environment';
import { CompaniesFacade } from '../../../../companies/+state/companies.facade';

@Component({
  selector: 'app-company-logo',
  templateUrl: './company-logo.component.html',
  styleUrls: ['./company-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyLogoComponent {

  @Input() class: string;
  @Input() link: string;
  company$: Observable<Company>;
  api_url = environment.api_url;

  constructor(private companiesFacade: CompaniesFacade) {
    this.company$ = this.companiesFacade.company$;
  }

  getLogoLink(company : any){
    return company?.logo_link ?? this.link
  }

  linkLogoClick(company : any){
    if(company?.logo_link){
      window.open(company?.logo_link, "_blank");
    } 
  }
}
