import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { SharedModule } from '../shared.module';

import { companiesFeatureKey, companiesInitialState, companiesReducer } from './+state/companies.reducer';
import { CompaniesEffects } from './+state/companies.effects';
import { CompaniesFacade } from './+state/companies.facade';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
    StoreModule.forFeature(companiesFeatureKey, companiesReducer, {initialState: companiesInitialState}),
    EffectsModule.forFeature([CompaniesEffects])
  ],
  providers: [
    CompaniesFacade,
  ]
})
export class CompaniesModule {
}
