
<div class="style-guide">
  <div class="container">

    <br><br>

    <h1>Seja Bem-Vindo(a) - Heading H1</h1>

    <br>

    <h2>Heading H2</h2>

    <h3>Últimos Imóveis Adicionados - Heading H3</h3>

    <p>
      <strong>Parágrafo normal</strong> - Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus voluptas
      officiis molestias distinctio. Illum omnis voluptate exercitationem explicabo tempore laboriosam, molestiae
      expedita saepe! Vitae sit architecto quasi adipisci enim tempora. Ullam aspernatur consequuntur tempora similique
      laboriosam alias consequatur tenetur repellendus omnis ipsum, suscipit soluta deserunt, saepe est a repudiandae
      asperiores, corrupti officiis praesentium cupiditate porro nisi quam. Modi ut magni iste facere ex maxime corrupti
      perferendis amet nihil iusto odio pariatur autem reiciendis, harum dolor facilis natus dignissimos, accusantium
      totam! Eos ipsa voluptatem aliquid! Ratione quidem natus voluptates adipisci aperiam, hic eveniet earum
      perspiciatis eligendi asperiores nobis sequi molestiae dolorum!
    </p>

    <p class="small">
      <strong>Parágrafo smaller</strong> - Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus voluptas
      officiis molestias distinctio. Illum omnis voluptate exercitationem explicabo tempore laboriosam, molestiae
      expedita saepe! Vitae sit architecto quasi adipisci enim tempora. Ullam aspernatur consequuntur tempora similique
      laboriosam alias consequatur tenetur repellendus omnis ipsum, suscipit soluta deserunt, saepe est a repudiandae
      asperiores, corrupti officiis praesentium cupiditate porro nisi quam. Modi ut magni iste facere ex maxime corrupti
      perferendis amet nihil iusto odio pariatur autem reiciendis, harum dolor facilis natus dignissimos, accusantium
      totam! Eos ipsa voluptatem aliquid! Ratione quidem natus voluptates adipisci aperiam, hic eveniet earum
      perspiciatis eligendi asperiores nobis sequi molestiae dolorum!
    </p>

    <hr>

    <app-button
      [label]="'Solid - Primary Color'"
      [btnstyle]="'btn_solid&#45;&#45;primary'"></app-button>

    <br><br>

    <app-button
      [label]="'Solid - Dark Grey 1 Color'"
      [btnstyle]="'btn_solid&#45;&#45;dark'"></app-button>

    <br><br>

    <app-button
      [label]="'Ghost - Dark Grey 1 Color'"
      [btnstyle]="'btn_ghost&#45;&#45;dark'"></app-button>

    <br><br>

    <app-button
      [label]="'Ghost - Primary Color'"
      [btnstyle]="'btn_ghost&#45;&#45;primary'"></app-button>
    <hr>

  </div>

  <section class="intersection" #intersection>
    <div class="intersection__content">
      <span>
          Surgiu alguma dúvida ou quer saber mais sobre os nossos produtos? Cadastre-se abaixo e nosso coordenador entrará em contato.
      </span>
      <br><br>
      <a class="btn_ghost&#45;&#45;white" href="#">Cadastre-se agora</a>
    </div>
  </section>

  <div class="container">

    <hr>

    <app-box
      [image]="'/assets/images/imovel1.jpg'"
      [title]="'Jardim Petrópolis 2'"
      [subtitle]="'Descrição'"
      [size]="'300'"
      [room]="'3'"
      [bath]="'2'"
      [parking]="'1'"
    ></app-box>

    <br>
    <hr>
    <br>

    <br><br>

    <section class="home__howto">
      <div class="howto__item">
        <div class="howto__icon">
          <img src="/assets/icons/ico_1.svg" alt="Faça seu cadastro">
        </div>
        <span>Faça seu cadastro</span>
        <span>Informe se você representa uma imobiliária ou corretor e crie sua senha de acesso.</span>
      </div>

      <div class="howto__item">
        <div class="howto__icon">
          <img src="/assets/icons/ico_2.svg" alt="Faça seu cadastro">
        </div>
        <span>Confirme seu cadastro</span>
        <span>Após seu cadastro você irá receber um e-mail de confirmação para ativação da conta.</span>
      </div>

      <div class="howto__item">
        <div class="howto__icon">
          <img src="/assets/icons/ico_3.svg" alt="Faça seu cadastro">
        </div>
        <span>Pronto!</span>
        <span>Agora é só pesquisar pelo imóvel que procura para ter acesso a todo o material de vendas.</span>
      </div>
    </section>

    <br>
    <hr>

    <app-avatar [name]="'Maria Alice'" [image]="'/assets/images/avatar-woman.jpg'"></app-avatar>

    <ul class="contacts">
      <li class="contacts__email"><a href="mailto:mariaalice@hypnobox.com.br">mariaalice@hypnobox.com.br</a></li>
      <li class="contacts__phone">11 9898 05446</li>
      <li class="contacts__whats">11 9898 05446</li>
    </ul>

    <hr>
    <br>

  </div>
</div>

<div class="prodetail">
  <section class="prodetail__header">
    <div class="container">
      <div class="buttons__wrapper">
        <a class="btn__square&#45;&#45;help" href="#"></a>
        <a class="btn__square&#45;&#45;link" href="#"></a>
      </div>
      <span>Jardim Petrópolis</span>
      <span>Add Vila Mariana</span>
    </div>
  </section>
  <nav>
    <div class="container">
      <ul>
        <li><a href="#" class="active">Sobre o Imóvel</a></li>
        <li><a href="#">Fotos</a></li>
        <li><a href="#">Características</a></li>
        <li><a href="#">Localização</a></li>
        <li><a href="#">Documentos</a></li>
      </ul>

      <div class="nav__holderbtns">
        <app-button
          [label]="'Falar com o coordenador'"
          [btnstyle]="'btn_solid&#45;&#45;primary&#45;&#45;bigger'"></app-button>
        <app-button
          [label]="'Copiar link de vendas'"
          [btnstyle]="'btn_solid&#45;&#45;primary&#45;&#45;bigger'"></app-button>
      </div>
    </div>
  </nav>
</div>

<div class="container">
  <ul class="prodetail__stats">
    <li>
      <span class="prodetail__stats&#45;&#45;area">Área</span>
      <span>220 m²</span>
    </li>
    <li>
      <span class="prodetail__stats&#45;&#45;room">Dormitórios</span>
      <span>3</span>
    </li>
    <li>
      <span class="prodetail__stats&#45;&#45;bath">Banheiros</span>
      <span>2</span>
    </li>
    <li>
      <span class="prodetail__stats&#45;&#45;parking">Vagas</span>
      <span>1</span>
    </li>
  </ul>

  <br><br>

  <div class="profeatures">
    <h4>Características principais</h4>
    <hr>
    <br>

    <ul class="profeatures__ul">
      <li>Fotos do apartamento para download</li>
      <li>Fotos do apartamento para download</li>
      <li>Informações de cada empreendimento</li>
      <li>Informações de cada empreendimento</li>
      <li>Filtros de buscas para a obra que deseja</li>
      <li>Filtros de buscas para a obra que deseja</li>
      <li>Materiais para treinamento: abordando clientes, etc</li>
      <li>Materiais para treinamento: abordando clientes, etc</li>
      <li>Contato com o coordenador: tirar dúvidas de maneira rápida e eficaz</li>
      <li>Contato com o coordenador: tirar dúvidas de maneira rápida e eficaz</li>
      <li>Ambiente seguro e personalizado acessível de qualquer lugar</li>
      <li>Ambiente seguro e personalizado acessível de qualquer lugar</li>
    </ul>

    <br><br>

    <h4>Documentos</h4>
    <hr>
    <br>
    <a href="#" class="document">
      <div class="document__info">
        <img src="/assets/icons/ico_document.svg" alt="">
        <div class="document__addr">
          <span>Jardim Petrópolis</span>
          <span>Add Vila Mariana</span>
        </div>
      </div>
      <span class="document__download">Baixar</span>
    </a>

    <a href="#" class="document">
      <div class="document__info">
        <img src="/assets/icons/ico_document.svg" alt="">
        <div class="document__addr">
          <span>Jardim Petrópolis</span>
          <span>Add Vila Mariana</span>
        </div>
      </div>
      <span class="document__download">Baixar</span>
    </a>

    <a href="#" class="document">
      <div class="document__info">
        <img src="/assets/icons/ico_document.svg" alt="">
        <div class="document__addr">
          <span>Jardim Petrópolis</span>
          <span>Add Vila Mariana</span>
        </div>
      </div>
      <span class="document__download">Baixar</span>
    </a>
  </div>

  <app-pagination [page]="1"></app-pagination>

</div>


<section class="prefooter-contact">
  <div class="container">

    <div class="prefooter-contact__infos">

      <img src="/assets/images/avatar-woman-large.jpg" class="avatar-large">

      <div class="prefooter-contact__wrapper">
        <h3>Ola meu nome é <strong>Fulana de Tal</strong> seu coordenador e estou aqui para ajudar.</h3>
        <ul class="contacts">
          <li class="contacts__email"><a href="mailto:mariaalice@hypnobox.com.br">mariaalice@hypnobox.com.br</a></li>
          <li class="contacts__phone">11 9898 05446</li>
          <li class="contacts__whats">11 9898 05446</li>
        </ul>

      </div>

    </div>

    <div class="prefooter-contact__form">
      <!--    <form action="" class="form__default">
           <textarea name="mensagem" placeholder="Mensagem" rows="12"></textarea>
           <app-button
             [label]="'Enviar'"
             [btnstyle]="'btn_solid&#45;&#45;primary'"></app-button>
         </form>
   -->
      <ul class="contacts">
        <li class="contacts__email"><a href="mailto:mariaalice@hypnobox.com.br">mariaalice@hypnobox.com.br</a></li>
        <li class="contacts__phone">11 9898 05446</li>
        <li class="contacts__whats">11 9898 05446</li>
      </ul>
    </div>

  </div>
</section>
