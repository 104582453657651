import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Product } from '../../shared/interfaces/product.interface';
import { ProductsFacade } from '../../products/+state/products.facade';
import { AuthFacade } from '../../registration/+state/auth.facade';
import { Company } from '../../shared/interfaces/company.interface';
import { CompaniesFacade } from '../../companies/+state/companies.facade';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  slideConfig = {
    nextArrow: '.slick-next', 
    prevArrow: '.slick-prev',  
    dots: true,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  company$: Observable<Company>;
  api_url = environment.api_url;
  products$: Observable<Product[]>;
  isLoggedIn$: Observable<boolean>;
  nameOfCompany: string;
  
  constructor(
    private authFacade: AuthFacade,
    private companiesFacade: CompaniesFacade,
    private productsFacade: ProductsFacade    
  ) {
    this.company$ = this.companiesFacade.company$;
  }

  ngOnInit(): void {
    let url = window.location.host;
    let urlSplit = url.split('.');
    let company = urlSplit[0];
    let name = '';

    if(company === 'ryazbek')
      name = 'R.Yazbek';
    else if(company === 'gamaro')
      name = 'Gamaro';
    else if(company === 'fibra')
      name = 'Fibra';
    else if(company === 'curyconstrutora'){
      name = 'Cury';
      Array.from(document.getElementsByClassName("intersection") as HTMLCollectionOf<HTMLElement>)[0].style.backgroundImage = "url('/assets/images/CurySP_Footer_Black.png')";
    }
    else if(company === 'exto')
      name = 'Exto';
    else if(company === 'curyconstrutorarj'){      
      name = 'Cury RJ';
      Array.from(document.getElementsByClassName("intersection") as HTMLCollectionOf<HTMLElement>)[0].style.backgroundImage = "url('/assets/images/CuryRJ_FOOTER_black.png')";
    }
    else if(company === 'zkf')
      name = 'ZKF';
    else if(company === 'sequoia'){
      name = 'SEQUOIA';
      Array.from(document.getElementsByClassName("intersection") as HTMLCollectionOf<HTMLElement>)[0].style.backgroundImage = "url('/assets/images/sequoia_inf_black (1).png')";
    }
    else if(company === 'hypnoboxparcerias'){
      name = 'Hypnobox Parcerias';
    }
    else if(company === 'start'){
      name = 'Start';
    }
    else if(company === 'mitre'){
      name = 'Mitre';
    }
    else if(company === 'alfa'){
      name = 'Alfa';
    }
    else if(company === 'steel'){
      name = 'Steel';
    }
    else if(company === 'aam'){
      name = 'AAM';
    }
    else if(company === 'paesegregori'){
      name = 'Paesegregori';
    }
    else if(company === 'vendascanopus'){
      name = 'Canopus';
    }
    
      else if(company === 'fraiha'){
      name = 'Fraiha';
    }

      else if(company === 'cs'){
      name = 'CS Vendas';
    }
    
        else if(company === 'wvmaldi'){
      name = 'WV Maldi';
    }
    
        else if(company === 'altti'){
      name = 'Altti';
    }
    

    //console.log(company);
    this.products$ = this.productsFacade.products$;
    this.productsFacade.getProducts({company});
    
    this.isLoggedIn$ = this.authFacade.isLoggedIn$;
    this.nameOfCompany = name;
  }

}
