<div class="products header_padding">

  <ng-container *ngIf="acessoLiberado == false">

    <div style="width:90%; margin:auto; min-height:500px; height:auto;">
      <h1>Seja Bem-Vindo(a)</h1>
      <h2>A sua solicitação de acesso aos dados do nosso portal está em análise.</h2> 
      <h3>Em breve você será notificado por e-mail.</h3>
    </div>

  </ng-container>
  
  <ng-container *ngIf="!acessoLiberado == false">
  <div class="products__headersidebar" (click)="toggleFilters()">
    <h3>Filtrar Imóveis</h3>
    <div class="arrow_menu" #arrowmenu>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
  <div class="container">
    <div class="products__sidebar" #sidebar>

      <div class="products__sidebar--row">
        <h2>Localização</h2>
        <label class="title">Estados</label>
        <ng-select (change)="filterProductsByFilter({Estado: $event})"
                   [items]="states$ | async"
                   class="custom"
                   bindLabel="name"
                   bindValue="id"
                   #estados
                   placeholder="Selecione um Estado">
        </ng-select>
        <br>

        <ng-container *ngIf="filters.value.Estado">
          <label class="title">Cidades</label>
          <ng-select #cidades
                     (change)="filterProductsByFilter({Cidade: $event})"
                     [items]="cities$ | async"
                     class="custom"
                     bindLabel="name"
                     bindValue="id"
                     placeholder="Selecione uma Cidade">
          </ng-select>
          <br>
        </ng-container>

        <ng-container *ngIf="filters.value.Cidade">
          <label class="title">Bairros</label>
          <div *ngFor="let bairro of bairros$ | async" class="checkbox">
            <input (change)="filterProductsByFilter({Bairro: bairro})" [value]="bairro" class="checkbox__input"
                   id="{{bairro}}"
                   type="checkbox">
            <label class="checkbox__label" for="{{bairro}}">{{bairro}}</label>
          </div>
          <br>
        </ng-container>
      </div>

      <div (click)="toggleAccordion($event, 1);" class="acc__header" id="accordPrincipais">
        Características Principais
      </div>
      <div class="acc">
        <div class="acc__content">
          <div class="row">
            <app-number-spinner (valueChange)="filterProductsByFilter({Dormitorios: $event})"
                                #dormitorios [min]="0" [value]="0"></app-number-spinner>
            <span>Dormitórios</span>
          </div>
          <div class="row">
            <app-number-spinner (valueChange)="filterProductsByFilter({Suites: $event})"
                                #suites [min]="0" [value]="0"></app-number-spinner>
            <span>Suítes</span>
          </div>
          <div class="row">
            <app-number-spinner (valueChange)="filterProductsByFilter({Banheiros: $event})"
                                #banheiros [min]="0" [value]="0"></app-number-spinner>
            <span>Banheiros</span>
          </div>
          <div class="row">
            <app-number-spinner (valueChange)="filterProductsByFilter({Vagas: $event})"
                                #vagas [min]="0" [value]="0"></app-number-spinner>
            <span>Vagas</span>
          </div>
        </div>
      </div>

      <div class="acc__header" id="accordFiltrar" (click)="toggleAccordion($event, 2);">
        Filtrar
      </div>
      <div class="acc">
        <div class="acc__content">
          <div class="filter__block">
            <span>Tipo de empreendimento</span>
            <div class="checkbox">
              <input (change)="filterProductsByFilter({TipoProduto: 'Vertical'})" class="checkbox__input"
                     id="cb_vertical"
                     type="checkbox">
              <label for="cb_vertical" class="checkbox__label">Vertical</label>
            </div>
            <div class="checkbox">
              <input (change)="filterProductsByFilter({TipoProduto: 'Horizontal'})" class="checkbox__input"
                     id="cb_horizontal"
                     type="checkbox">
              <label for="cb_horizontal" class="checkbox__label">Horizontal</label>
            </div>
            <div class="checkbox">
              <input (change)="filterProductsByFilter({TipoProduto: 'Vertical e Horizontal'})" class="checkbox__input"
                     id="cb_horizontalvertical"
                     type="checkbox">
              <label for="cb_horizontalvertical" class="checkbox__label">Vertical e Horizontal</label>
            </div>
          </div>

          <div class="filter__block">
            <span>Finalidade</span>
            <div class="checkbox">
              <input (change)="filterProductsByFilter({Finalidade: 'Residencial'})" class="checkbox__input"
                     id="cb_residencial"
                     type="checkbox">
              <label for="cb_residencial" class="checkbox__label">Residencial</label>
            </div>
            <div class="checkbox">
              <input (change)="filterProductsByFilter({Finalidade: 'Comercial'})" class="checkbox__input"
                     id="cb_comercial"
                     type="checkbox">
              <label for="cb_comercial" class="checkbox__label">Comercial</label>
            </div>
            <div class="checkbox">
              <input (change)="filterProductsByFilter({Finalidade: 'Comercial e Residencial'})" class="checkbox__input"
                     id="cb_comercial_residencial"
                     type="checkbox">
              <label class="checkbox__label" for="cb_comercial_residencial">Comercial e Residencial</label>
            </div>
          </div>

          <div *ngIf="filters.value.priceOptions?.max" class="filter__block">
            <span>Faixa de preço</span>
            <div class="products__rangeslider">
              <ng5-slider [(highValue)]="filters.value.priceOptions.max"
                          (userChangeEnd)="filterProductsByFilter({ priceOptions: { min: $event.value, max: $event.highValue, touched: true}})"
                          [options]="priceOptions"
                          [value]="filters.value.priceOptions.min"></ng5-slider>
            </div>
          </div>

          <div *ngIf="filters.value.sizeOptions?.max" class="filter__block">
            <span>Metragem da unidade</span>
            <div class="products__rangeslider">
              <ng5-slider
                (userChangeEnd)="filterProductsByFilter({ sizeOptions: { min: $event.value, max: $event.highValue}})"
                [highValue]="filters.value.sizeOptions.max"
                [options]="sizeOptions"
                [value]="filters.value.sizeOptions.min"></ng5-slider>
            </div>
          </div>

          <div class="filter__block">
            <span>Status</span>
            <div class="checkbox">
              <input (change)="filterProductsByFilter({FaseProduto: 'Breve Lançamento'})" class="checkbox__input"
                     id="cb_breve-lancamento"
                     type="checkbox">
              <label class="checkbox__label" for="cb_breve-lancamento">Breve Lançamento</label>
            </div>
            <div class="checkbox">
              <input (change)="filterProductsByFilter({FaseProduto: 'Em construção'})" class="checkbox__input"
                     id="cb_em-construcao"
                     type="checkbox">
              <label class="checkbox__label" for="cb_em-construcao">Em construção</label>
            </div>
            <div class="checkbox">
              <input (change)="filterProductsByFilter({FaseProduto: 'Lançamento'})" class="checkbox__input"
                     id="cb_lancamento"
                     type="checkbox">
              <label for="cb_lancamento" class="checkbox__label">Lançamento</label>
            </div>
            <div class="checkbox">
              <input (change)="filterProductsByFilter({FaseProduto: 'Obras concluídas'})" class="checkbox__input"
                     id="cb_obras"
                     type="checkbox">
              <label for="cb_obras" class="checkbox__label">Obras Concluídas</label>
            </div>
            <div class="checkbox">
              <input (change)="filterProductsByFilter({FaseProduto: 'Pronto para morar'})" class="checkbox__input"
                     id="cb_pronto"
                     type="checkbox">
              <label for="cb_pronto" class="checkbox__label">Pronto para morar</label>
            </div>
          </div>

          <div class="filter__block">
            <app-button
              [btnstyle]="'btn_ghost--dark perc100'"
              [label]="'Limpar Filtros'"
              (click)="clearFilters()"
            ></app-button>
          </div>

        </div>
      </div>
    </div>
    <div class="products__showcase">
      <div class="products__showcase--wrapper">
        <ng-container *ngFor="let product of products$ | async">
          <app-box
            [image]="product.FotoCapa?.Caminho"
            [link]="'/imovel/'+product.CodProduto"

            [room]="+product.UnidadesDeAte.TotalDormitorioDe === product.UnidadesDeAte.TotalDormitorioAte ? 
            product.UnidadesDeAte.TotalDormitorioDe : product.UnidadesDeAte.TotalDormitorioDe + ' a ' 
            + product.UnidadesDeAte.TotalDormitorioAte"

            [size]="+product.UnidadesDeAte.TotalAreaUtilDe === product.UnidadesDeAte.TotalAreaUtilAte ?
            product.UnidadesDeAte.TotalAreaUtilDe:
            product.UnidadesDeAte.TotalAreaUtilDe + ' m² a ' + product.UnidadesDeAte.TotalAreaUtilAte"

            [subtitle]="product.Produto"
            
            [bath]="+product.UnidadesDeAte.TotalBanheiroDe === product.UnidadesDeAte.TotalBanheiroAte ? 
            product.UnidadesDeAte.TotalBanheiroDe : product.UnidadesDeAte.TotalBanheiroDe + ' a ' 
            + product.UnidadesDeAte.TotalBanheiroAte"

            [parking]="+product.UnidadesDeAte.TotalVagaDe === product.UnidadesDeAte.TotalVagaAte ? 
              product.UnidadesDeAte.TotalVagaDe : product.UnidadesDeAte.TotalVagaDe + ' a ' 
              + product.UnidadesDeAte.TotalVagaAte"
            [title]="product.Endereco?.Bairro"
            [fase]="product.FaseProduto"
          ></app-box>
        </ng-container>
        <ng-container *ngIf="!(products$ | async).length">
          Nenhum imóvel encontrado com os filtros selecionados!
        </ng-container>
      </div>
    </div>
  </div>
  </ng-container>
</div>
