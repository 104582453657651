import { RegistrationService } from '../registration.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import * as AuthActions from './auth.actions';
import { LocalStorageJwtService } from '../../shared/services/local-storage-jwt.service';
import { ModalsService } from '../../components/modals/modals.service';
import { LoaderService } from '../../shared/services/loader.service';


@Injectable()
export class AuthEffects {
  getUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getUser),
      switchMap(item =>
        this.registrationService.user().pipe(
          map(data => AuthActions.getUserSuccess({user: data})),
          catchError(error => of(AuthActions.getUserFail(error))),
        ),
      ),
    ),
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      switchMap(action => {
          this.localStorageJwtService.removeItem();
          return this.registrationService.login(action.loginUser).pipe(
            map(response => AuthActions.loginSuccess({user: {...response.user, token: response.jwt}})),
            catchError(error => {
              this.modalsService.modalError('Usuário não encontrado ou senha incorreta.');
              this.localStorageJwtService.removeItem();
              return of(AuthActions.loginFail());
            })
          );
        }
      ),
    ),
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.loginSuccess),
        tap(action => {
          this.localStorageJwtService.setItem(action.user.token);
          this.router.navigateByUrl('/logado');
        }),
      ),
    {dispatch: false},
  );

  register$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.register),
      switchMap(action =>
        this.registrationService.register(action.newUser).pipe(
          map(response => AuthActions.registerSuccess({user: {...response.user, token: response.jwt}})),
          catchError(error => {
            //console.log(error);
            this.modalsService.modalError('Email já cadastrado no sistema');
            this.localStorageJwtService.removeItem();
            return of(AuthActions.registerFail());
          }),
        ),
      ),
    ),
  );

  registerSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.registerSuccess),
        tap(action => {
          this.localStorageJwtService.setItem(action.user.token);
          this.router.navigateByUrl('cadastro/area-atuacao');
        }),
      ),
    {dispatch: false},
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.update),
      switchMap(action =>
        this.registrationService.update(action.user).pipe(
          map(response => AuthActions.updateSuccess({user: response})),
          catchError(error => {
            this.localStorageJwtService.removeItem();
            return of(AuthActions.updateFail());
          }),
        ),
      ),
    ),
  );

  updateSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.updateSuccess),
        tap(action => {
          this.modalsService.modalSuccess('Cadastro atualizado com sucesso!');
          this.router.navigateByUrl('/logado');
        }),
      ),
    {dispatch: false},
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logout),
        tap(action => {
          this.localStorageJwtService.removeItem();
          this.router.navigateByUrl('/');
        }),
      ),
    {dispatch: false},
  );

  forgotPassword$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.forgotPassword),
        switchMap(action =>
          this.registrationService.forgotPassword(action.email).pipe(
            map(response => {
              this.loaderService.close();
              this.modalsService.modalForgotPasswordSaved();
              this.router.navigateByUrl('login');
              return of(response);
            }),
            catchError(error => {
              this.loaderService.close();
              this.modalsService.modalError('Dados para recuperação inválidos!');
              this.localStorageJwtService.removeItem();
              return of(error);
            }),
          ),
        ),
      ),
    {dispatch: false},
  );

  resetPassword$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.resetPassword),
        switchMap(action =>
          this.registrationService.resetPassword(action.resetPassword).pipe(
            map(response => {
              this.modalsService.modalSuccess('Cadastro atualizado com sucesso!');
              this.router.navigateByUrl('login');
              return of(response);
            }),
            catchError(error => {
              this.modalsService.modalError('Não foi possível atualizar o cadastro!');
              this.localStorageJwtService.removeItem();
              return of(error);
            }),
          ),
        ),
      ),
    {dispatch: false},
  );

  constructor(
    private actions$: Actions,
    private localStorageJwtService: LocalStorageJwtService,
    private modalsService: ModalsService,
    private registrationService: RegistrationService,
    private router: Router,
    private loaderService: LoaderService,
  ) {
  }
}
