import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { BoxComponent } from './components/template/ui/box/box.component';
import { ButtonComponent } from './components/template/ui/button/button.component';
import { CompanyLogoComponent } from './components/template/ui/company-logo/company-logo.component';
import { NumberSpinnerComponent } from './components/template/ui/number-spinner/number-spinner.component';
import { PaginationComponent } from './components/template/ui/pagination/pagination.component';
import { RegisterPaginationComponent } from './components/template/ui/register-pagination/register-pagination.component';

@NgModule({
  declarations: [
    ButtonComponent,
    BoxComponent,
    NumberSpinnerComponent,
    PaginationComponent,
    RegisterPaginationComponent,
    CompanyLogoComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SweetAlert2Module,
  ],
  exports: [
    ButtonComponent,
    BoxComponent,
    NumberSpinnerComponent,
    PaginationComponent,
    RegisterPaginationComponent,
    CompanyLogoComponent
  ]
})
export class SharedModule {
}
