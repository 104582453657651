import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as tinycolor from 'tinycolor2';

import * as CompaniesActions from './companies.actions';
import { ModalsService } from '../../components/modals/modals.service';
import { CompaniesService } from '../companies.service';


@Injectable()
export class CompaniesEffects {
  getCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActions.getCompany),
      switchMap(action =>
        this.companiesService.getCompanyBySubdomain(action.subdomain).pipe(
          map(data => {
            document.documentElement.style.setProperty(`--primary`, data.color);
            document.documentElement.style.setProperty(`--primary-darken`, tinycolor(data.color).darken().toString());
            document.documentElement.style.setProperty(`--primary-lighten`, tinycolor(data.color).lighten(35).toString());
            return CompaniesActions.getCompanySuccess({company: data});
          }),
          catchError(error => of(CompaniesActions.getCompanyFail(error))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private modalsService: ModalsService,
    private companiesService: CompaniesService
  ) {
  }
}
