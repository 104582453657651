import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthFacade } from '../+state/auth.facade';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from '../../shared/services/loader.service';

@Component({
  selector: 'app-forget',
  templateUrl: './forget.component.html',
  styleUrls: ['./forget.component.scss']
})
export class ForgetComponent implements OnInit {

  forgetForm: FormGroup;
  submitted = false;
  hasResetPasswordToken: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private authFacade: AuthFacade,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
  ) {
    this.route.queryParams
      .subscribe(params => {
        this.hasResetPasswordToken = params.hasOwnProperty('code');
      }
    );
  }

  ngOnInit(): void {
    this.forgetForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]]
    });
  }

  get f(): { [key: string]: AbstractControl; } {
    return this.forgetForm.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.forgetForm.valid) {
      this.loaderService.open();
      this.authFacade.forgotPassword(this.forgetForm.value);
    }
  }

}
