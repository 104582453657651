import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-box',
  templateUrl: './box.component.html',
  styleUrls: ['./box.component.scss']
})
export class BoxComponent implements OnInit {

  @Input() image: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() size: number;
  @Input() room: number;
  @Input() bath: number;
  @Input() parking: number;
  @Input() link: string;
  @Input() fase: string;

  // public myId: string = 'testId';

  constructor() { }

  ngOnInit(): void {
  }

}
