import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-styleguide-login',
  templateUrl: './styleguide-login.component.html',
  styleUrls: ['./styleguide-login.component.scss']
})
export class StyleguideLoginComponent implements OnInit {

  loginPages: boolean = true;
  loginPagesString: string = 'págna de login';

  constructor() { }

  ngOnInit(): void {
  }

}
