<div class="modals">

  <br>
  <br>
  <br>
  <br>

  <div class="container">
    <app-button
      (click)="modalExit()"
      [label]="'modal : Exit'"
      [btnstyle]="'btn_solid--primary'" ></app-button>

    <app-button
      (click)="modalSend()"
      [label]="'modal : Send'"
      [btnstyle]="'btn_solid--primary'" ></app-button>

    <app-button
      (click)="modalWrongPassword()"
      [label]="'modal : Wrong Password'"
      [btnstyle]="'btn_solid--primary'" ></app-button>

    <app-button
      (click)="modalPasswordSaved()"
      [label]="'modal : Password Saved'"
      [btnstyle]="'btn_solid--primary'" ></app-button>

    <app-button
      (click)="modalBlock()"
      [label]="'modal : Block'"
      [btnstyle]="'btn_solid--primary'" ></app-button>

      <!-- <div class="some-element" jpPreload="/assets/images/ilustra_exit.svg"></div>
      <div class="some-element" jpPreload="/assets/images/ilustra_send.svg"></div>
      <div class="some-element" jpPreload="/assets/images/ilustra_usernotfound.svg"></div>
      <div class="some-element" jpPreload="/assets/images/ilustra_pwd_changed.svg"></div>
      <div class="some-element" jpPreload="/assets/images/ilustra_block.svg"></div> -->

  </div>

  <br>
  <br>

</div>
