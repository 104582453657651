<div *ngIf="!hasResetPasswordToken" class="register">
    <div class="register__modal">
      <app-company-logo [class]="'logo'" [link]="''"></app-company-logo>
        <div class="register__infos">
            <h1>Informe o e-mail de cadastro para criar uma nova senha</h1>
            <form [formGroup]="forgetForm" (ngSubmit)="onSubmit()" class="form__default login__form">
                <div class="form-group">
                    <label [ngClass]="{ 'is-invalid-label': submitted && f.email.errors }">E-mail</label>
                    <input type="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="Informe um e-mail válido" required>
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">Campo Obrigatório</div>
                        <div *ngIf="f.email.errors.pattern">Necessário email válido</div>
                    </div>
                </div>

                <button class="btn_solid--blocked--primary" type="submit" >Avançar</button>
            </form>
            <app-button
                routerLink="/login"
                [label]="'Voltar'"
                [btnstyle]="'btn_solid--blocked--white'"></app-button>
        </div>
    </div>
</div>

<app-reset-password *ngIf="hasResetPasswordToken"></app-reset-password>