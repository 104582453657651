import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Ng5SliderModule } from 'ng5-slider';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { SharedModule } from '../shared.module';
import { ProductsRoutingModule } from './products.routing.module';
import { ProductsComponent } from './products.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { productsFeatureKey, productsInitialState, productsReducer } from './+state/products.reducer';
import { ProductsEffects } from './+state/products.effects';
import { ProductsFacade } from './+state/products.facade';
import { ReplaceHttp } from '../pipe-replace';
import { MatExpansionModule } from '@angular/material/expansion';


@NgModule({
  declarations: [
    ProductsComponent,
    ProductDetailComponent,
    ReplaceHttp
  ],
  imports: [
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD7Ic5Mojd1bPvZmPUEBZC7cqytGwGClQk'
    }),
    CommonModule,
    ProductsRoutingModule,
    Ng5SliderModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollToModule.forRoot(),
    SharedModule,
    StoreModule.forFeature(productsFeatureKey, productsReducer, {initialState: productsInitialState}),
    EffectsModule.forFeature([ProductsEffects]),
    MatExpansionModule
  ],
  providers: [
    ProductsFacade,
    GoogleMapsAPIWrapper
  ]
})
export class ProductsModule {
}
