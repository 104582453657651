import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  District,
  LoginUser,
  NewUser,
  Region,
  ResetPassword,
  User,
  UserResponse
} from '../shared/interfaces/user.interface';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(private httpClient: HttpClient) {
  }

  user(): Observable<User> {
    return this.httpClient.get<User>(environment.api_url + '/users/me');
  }

  login(credentials: LoginUser): Observable<UserResponse> {
    return this.httpClient.post<UserResponse>(environment.api_url + '/auth/local', credentials);
  }

  forgotPassword(email: string): Observable<string> {
    return this.httpClient.post<string>(environment.api_url + '/auth/forgot-password', email);
  }

  resetPassword(resetPassword: ResetPassword): Observable<string> {
    return this.httpClient.post<string>(environment.api_url + '/auth/reset-password', resetPassword);
  }

  register(newUser: NewUser): Observable<UserResponse> {
    return this.httpClient.post<UserResponse>(environment.api_url + '/auth/local/register', newUser);
  }

  update(user: User): Observable<User> {
    return this.httpClient.put<User>(environment.api_url + '/users/' + user.id, user);
  }

  getRegions(): Observable<Region[]> {
    return this.httpClient.get<Region[]>(environment.api_url + '/regions');
  }

  getDistricts(): Observable<District[]> {
    return this.httpClient.get<District[]>(environment.api_url + '/districts');
  }
}
