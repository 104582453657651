import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ConfirmedValidator } from './confirmed.validators';
import { AuthFacade } from '../+state/auth.facade';
import { NewUser } from '../../shared/interfaces/user.interface';
import { RegistrationService } from '../registration.service';
import { LocalStorageJwtService } from '../../shared/services/local-storage-jwt.service';

@Component({
  selector: 'app-register-password',
  templateUrl: './register-password.component.html',
  styleUrls: ['./register-password.component.scss']
})

export class RegisterPasswordComponent implements OnInit {

  passwordForm: FormGroup;
  submitted = false;
  newUser: NewUser;
  registrationRouteByPerfil: string;

  constructor(
    private formBuilder: FormBuilder,
    private authFacade: AuthFacade,
    private router: Router,
    private registration: RegistrationService,
    private localStorageJwtService: LocalStorageJwtService) {
    const navigation = this.router.getCurrentNavigation();
    this.newUser = navigation.extras.state as NewUser;
    if (!this.newUser?.email) {
      this.router.navigateByUrl('cadastro/selecionar');
    }
  }

  ngOnInit(): void {

    if(this.newUser.perfil === "Autônomo"){
      this.registrationRouteByPerfil = "/cadastro/autonomo";
    }else if(this.newUser.perfil === "Imobiliária"){
      this.registrationRouteByPerfil = "/cadastro/imobiliaria";
    }else{
      this.registrationRouteByPerfil = "/cadastro/selecionar";
    }

    this.passwordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirm_password: ['', [Validators.required]]
    }, {
      validators: ConfirmedValidator('password', 'confirm_password')
    });
  }

  get f(): { [key: string]: AbstractControl; } {
    return this.passwordForm.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.passwordForm.valid) {
      //  Ao iniciar um cadastro, faz login com o adm, pois precisa jwt dele para fazer a requisição
      this.localStorageJwtService.removeItem();
      this.registration.login({identifier: 'hypnobox@gmail.com', password: 'M2G5@8EmGPZD'})
        .subscribe(
          user => {
            this.localStorageJwtService.setItem(user.jwt);
            
            this.authFacade.register({
              ...this.newUser,
              password: this.passwordForm.get('password').value,
              username: this.newUser.email
            });
          }
        );
    }
  }

}
