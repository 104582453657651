import { Injectable } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Injectable({
  providedIn: 'root'
})
export class ModalsService {

  constructor() {
  }

  modalSuccess(message: string): void {
    Swal.fire({
      html: message,
      width: 450,
      imageHeight: 204,
      imageUrl: '/assets/images/ilustra_pwd_changed.svg',
      confirmButtonText: 'Ok, Entendi',
      customClass: {
        popup: 'modal__forgot__password',
        confirmButton: 'modal__btn--primary',
      },
      buttonsStyling: false
    });
  }

  modalError(message: string): void {
    Swal.fire({
      html: message,
      width: 450,
      imageHeight: 204,
      imageUrl: '/assets/images/ilustra_block.svg',
      confirmButtonText: 'Ok, Entendi',
      customClass: {
        popup: 'modal__forgot__password',
        confirmButton: 'modal__btn--primary',
      },
      buttonsStyling: false
    });
  }

  modalForgotPasswordSaved(): void {
    Swal.fire({
      html: 'Dados para recuperação enviados para o email informado!',
      width: 450,
      imageHeight: 204,
      imageUrl: '/assets/images/ilustra_send.svg',
      confirmButtonText: 'Ok, Entendi',
      customClass: {
        popup: 'modal__forgot__password',
        confirmButton: 'modal__btn--primary',
      },
      buttonsStyling: false
    });
  }

}
