import { createFeatureSelector, createSelector } from '@ngrx/store';

import { productsFeatureKey, ProductState } from './products.reducer';

export const getProductsFeature = createFeatureSelector<ProductState>(productsFeatureKey);
export const getProducts = createSelector(getProductsFeature, (products: ProductState) => products.products);
export const getStates = createSelector(getProductsFeature, (products: ProductState) => products.states);
export const getCities = createSelector(getProductsFeature, (products: ProductState) => products.cities);
export const getBairros = createSelector(getProductsFeature, (products: ProductState) => products.bairros);
export const getPrices = createSelector(getProductsFeature, (products: ProductState) => products.prices);
export const getSizes = createSelector(getProductsFeature, (products: ProductState) => products.sizes);

export const productsQuery = {
  getProducts,
  getStates,
  getCities,
  getBairros,
  getPrices,
  getSizes
};
