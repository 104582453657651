import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidateBrService } from 'angular-validate-br';
import { UserPerfil } from '../../shared/interfaces/user.interface';
import { ProductsService } from '../../products/products.service';

@Component({
  selector: 'app-agency',
  templateUrl: './agency.component.html',
  styleUrls: ['./agency.component.scss'],
})
export class AgencyComponent implements OnInit {
  agencyForm: FormGroup;
  submitted = false;
  coordenadores: any[] = [];
  coordenadoresAtivo: string;
  parametros: any[] = [{
    'politica_de_privacidade_cliente':0,
    'politica_de_privacidade_cliente_link':0,
    'politica_de_privacidade_cliente_link_texto':0,
    'politica_de_privacidade_cliente_texto':0,
    'politica_de_privacidade_cliente_obrigatoriedade':0,
    'alias_amazon':null,
    'url_amazon':null,
    'dados_coordenador_portal':null
  }];
  parametrosAtivo: string;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private validateBrService: ValidateBrService,
    private productService: ProductsService,
  ) {
  }

  ngOnInit(): void {
    
    let url = window.location.host;
    let urlSplit = url.split('.');
    let company = urlSplit[0];

    this.productService.getParameters(company,"politica_de_privacidade_cliente,politica_de_privacidade_cliente_link,politica_de_privacidade_cliente_link_texto,politica_de_privacidade_cliente_texto,politica_de_privacidade_cliente_obrigatoriedade,alias_amazon,url_amazon,dados_coordenador_portal").then(x => {
      for (let index = 0; index < Object.keys(x['Parametros']).length; index++) {
        if (Object.keys(x['Parametros'])[index]) {
          this.parametros[0][Object.keys(x['Parametros'])[index]] = x['Parametros'][Object.keys(x['Parametros'])[index]];
        }
      }
      this.parametrosAtivo = 'ativo';
    });

    this.productService.getCoordinator(company).then(y => {
      for (let index2 = 0; index2 < y.length; index2++) {
        const element = y[index2];
        this.coordenadores.push(element);
      } 
      this.coordenadoresAtivo = 'ativo';
    });

    this.agencyForm = this.formBuilder.group({
      razao_social: ['',[Validators.required]],
      email: ['', [
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]
      ],
      telefone: ['',[Validators.required]],
      cnpj: ['', [
        Validators.required,
        this.validateBrService.cnpj]
      ],
      creci: ['', [Validators.required]],
      perfil: [UserPerfil.IMOBILIARIA],
      empresa: company,
      coordenador: [''],
      politica_privacidade: [''],
      nome_responsavel: [''],
      creci_responsavel: [''],
      cpf_responsavel: ['', [
        Validators.pattern('[0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2}'),
        this.validateBrService.cpf]
      ]
    });
  }

  get f(): { [key: string]: AbstractControl; } {
    return this.agencyForm.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    let politica_de_privacidade_cliente_obrigatoriedade = document.querySelector("#politica_de_privacidade_cliente_obrigatoriedade_1");
    var politica_privacidade = document.getElementById("politica_privacidade") ? document.getElementById("politica_privacidade") : null;
    var politica_privacidade_checked = politica_privacidade ? politica_privacidade.getAttribute('checked') : null;


    var temCoordenador = document.querySelector("#select-coordenador-div").classList.contains('none');
    var coordenadorSelecionado = false;
    var allElements = document.querySelectorAll(".brokers");

    for(let i=0; i<allElements.length; i++)
    { 
      if (allElements[i].classList.contains("coordinator-selector")) {
        coordenadorSelecionado = true;
        break;
      }
    }

    if(!temCoordenador && !coordenadorSelecionado){
      document.querySelector("#tem-coordenador-error").classList.remove('none');
    }else{
      if(politica_de_privacidade_cliente_obrigatoriedade && (!politica_privacidade_checked || politica_privacidade_checked == 'false')){
        document.getElementById('politica-privacidade-error').classList.remove('none');
      } else if(politica_de_privacidade_cliente_obrigatoriedade && politica_privacidade_checked == 'true' || !politica_de_privacidade_cliente_obrigatoriedade){
        if (this.agencyForm.valid) {
          this.router.navigateByUrl('cadastro/registra-senha', {state: this.agencyForm.value});
        }
      }
    }
  }

  removeClass(id)
  {

    document.querySelector("#tem-coordenador-error").classList.add('none');

    var allElements = document.querySelectorAll(".brokers");
    for(let i=0; i<allElements.length; i++)
    { 
      allElements[i].classList.remove('coordinator-selector');
    }

    if(id) {
      var element = document.querySelector("#select-coordenador-"+id);
      element.classList.add('coordinator-selector');
  
      this.agencyForm.patchValue({coordenador: id});
    } else {
      this.agencyForm.patchValue({coordenador: ''});
    }
  }

  changePoliticaValue()
  {
    var element = document.getElementById("politica_privacidade");
    var checked = element.getAttribute('checked');
    
    if(checked == 'true'){
      element.setAttribute('checked', 'false');
      this.agencyForm.patchValue({politica_privacidade: ''});
    }else{
      element.setAttribute('checked', 'true');
      this.agencyForm.patchValue({politica_privacidade: '1'});
    }
  }

}
