<ng-container *ngIf="product$ | async as product">
  
  <ng-container *ngIf="acessoLiberado == false;">

    <div style="padding-top:80px; width:90%; margin:auto; min-height:500px; height:auto;">
      <h1>Seja Bem-Vindo(a)</h1>
      <h2>A sua solicitação de acesso aos dados do nosso portal está em análise.</h2> 
      <h3>Em breve você será notificado por e-mail.</h3>
    </div>

  </ng-container>

  <ng-container *ngIf="!acessoLiberado == false">

    <div class="prodetail header_padding">

      <section [ngStyle]="{'background-image': 'url('+product.FotoCapa.Caminho+')',
      'background-color': 'rgba(0, 0, 0, 0.5)',
      'background-blend-mode': 'multiply'}" class="prodetail__header">
        <div class="container">
          <!-- <div class="buttons__wrapper">
            <a class="btn__square--help" href="#"></a>
            <a class="btn__square--link" href="#"></a>
          </div> -->
          <span>{{product.Endereco?.Bairro}}</span>
          <span>{{product.Produto}}</span>
        </div>
      </section>

      <nav [class.sticky]='sticky' #stickyMenu>
        <div class="container">
          <ul>
            <li><span [ngxScrollToDuration]="ngxScrollToDuration" [ngxScrollToEasing]="ngxScrollToEasing"
                      [ngxScrollToOffset]="ngxScrollToOffset"
                      [ngxScrollTo]="'#sobre'">Sobre o Imóvel</span></li>
            <li><span [ngxScrollToDuration]="ngxScrollToDuration" [ngxScrollToEasing]="ngxScrollToEasing"
                      [ngxScrollToOffset]="ngxScrollToOffset" [ngxScrollTo]="'#fotos'">Fotos</span></li>
            <!-- <li><span [ngxScrollToDuration]="ngxScrollToDuration" [ngxScrollToEasing]="ngxScrollToEasing"
                      [ngxScrollToOffset]="ngxScrollToOffset"
                      [ngxScrollTo]="'#caracteristicas'">Características</span></li> -->
            <li><span [ngxScrollToDuration]="ngxScrollToDuration" [ngxScrollToEasing]="ngxScrollToEasing"
                      [ngxScrollToOffset]="ngxScrollToOffset" [ngxScrollTo]="'#localizacao'">Localização</span>
            </li>
            <li><span [ngxScrollToDuration]="ngxScrollToDuration" [ngxScrollToEasing]="ngxScrollToEasing"
                      [ngxScrollToOffset]="ngxScrollToOffset" [ngxScrollTo]="'#documentos'">Documentos</span>
            </li>
          </ul>

          <div class="nav__holderbtns">
            <!-- <app-button
              (click)="modalCopied()"
              [btnstyle]="'btn_solid--primary--bigger'"
              [icon]="'ico_link'"
              [label]="'Copiar link de vendas'"></app-button> -->
          </div>
        </div>
      </nav>

      <div class="container" id="sobre">
        <ul class="prodetail__stats">
          <li>
            <span class="prodetail__stats--area">Área</span>
            <span>
              {{ product.UnidadesDeAte.TotalAreaUtilDe === product.UnidadesDeAte.TotalAreaUtilAte ?
                 product.UnidadesDeAte.TotalAreaUtilDe + ' m²' :
                 product.UnidadesDeAte.TotalAreaUtilDe + ' m² até ' + product.UnidadesDeAte.TotalAreaUtilAte + ' m²' }}
            </span>            
          </li>
          <li>
            <span class="prodetail__stats--room">Dormitórios</span>
            <span>{{product.UnidadesDeAte.TotalDormitorioDe === product.UnidadesDeAte.TotalDormitorioAte ? 
                    product.UnidadesDeAte.TotalDormitorioDe : product.UnidadesDeAte.TotalDormitorioDe + ' a ' 
                    + product.UnidadesDeAte.TotalDormitorioAte }}
            </span>
          </li>
          <li>
            <span class="prodetail__stats--bath">Banheiros</span>
            <span>{{product.UnidadesDeAte.TotalBanheiroDe === product.UnidadesDeAte.TotalBanheiroAte ? 
              product.UnidadesDeAte.TotalBanheiroDe : product.UnidadesDeAte.TotalBanheiroDe + ' a ' 
              + product.UnidadesDeAte.TotalBanheiroAte }}
            </span>
          </li>
          <li>
            <span class="prodetail__stats--parking">Vagas</span>
            <span>{{product.UnidadesDeAte.TotalVagaDe === product.UnidadesDeAte.TotalVagaAte ? 
              product.UnidadesDeAte.TotalVagaDe : product.UnidadesDeAte.TotalVagaDe + ' a ' 
              + product.UnidadesDeAte.TotalVagaAte }}
            </span>
          </li>
        </ul>

        <br><br>

        <div class="prodetail__desc">
          <p [innerHTML]="processDescription(product.Descricao)"></p>
        </div>

        <h1 style="margin-top: 4rem; text-align: center;">Tipologias</h1>

        <ng-container *ngFor ="let unidade of product.Unidades">
          <mat-accordion>
            <mat-expansion-panel style="margin-bottom: 0.5rem;">
              <mat-expansion-panel-header>
                <mat-panel-title style="text-transform: uppercase;">
                  {{unidade.TipoUnidade}}
                </mat-panel-title>
                <mat-panel-description>
                  {{unidade.Finalidade}}
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="container-tipology-data">
                <div class="container-tipology-data__properties">
                  <ul class="typology-list">
                    <li>
                      <span class="TotalDormitorio">{{unidade.TotalDormitorio}} Dormitórios(s)</span>
                    </li>
                    <li>
                      <span class="TotalBanheiro">{{unidade.TotalBanheiro}} Banheiro(s)</span>
                    </li>
                    <li>
                      <span class="TotalSuite">{{unidade.TotalSuite}} Suíte(s)</span>
                    </li>
                    <li>
                      <span class="TotalVaga">{{unidade.TotalVaga}} Vaga(s)</span>
                    </li>
                    <li>
                      <span class="TotalComodo">{{unidade.TotalComodos}} Comodos(s)</span>
                    </li>
                    <li>
                      <span class="TotalPeDireito">{{unidade.TotalPeDireito}}m Pé Direito </span>
                    </li>
                  </ul>

                  <div class="container-areas">
                    <div class="container-areas-util">
                      <span class="container-areas-util__value">{{unidade.TotalAreaUtil}}m²</span>
                      <span class="container-areas-util__label">Área útil</span>
                    </div>
                    <div class="container-areas-total">
                      <span class="container-areas-total__value">{{unidade.TotalAreaTotal}}m²</span>
                      <span class="container-areas-total__label">Área total</span>
                    </div>                    
                  </div>
                </div>
                
                <div class="container-tipology-data__value">
                  <span>{{ unidade.Valor | currency:'BRL':'symbol':'1.2-2' }}</span>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </ng-container>        
      </div>

      <!-- GALLERY -->
      <div class="prodetail__gallery" id="fotos">
        <!-- <div #fotoSelecionada [ngStyle]="{'background-image': 'url('+product.Fotos[0]?.Caminho+')'}"
            class="prodetail__gallery--wrapper"></div> -->
          <h1>Galeria de Imagens</h1>
        <ng-container *ngIf="product.Fotos">
          <div class="prodetail__gallery--thumbs">
            <ng-container *ngFor="let foto of product.Fotos">
                <div><img (click)="openModal(foto.Caminho, foto.Titulo, foto.Descricao)" [alt]="foto.Descricao"
                          [src]="foto.Caminho"></div>
            </ng-container>
          </div>
        </ng-container>
      </div>

    </div>

    <!-- <div class="container" id="caracteristicas">
      <div class="profeatures">
        <h4>Características principais</h4>
        <hr>
        <br>
        <ul class="profeatures__ul">
          <li>Fotos do apartamento para download</li>
          <li>Fotos do apartamento para download</li>
          <li>Informações de cada empreendimento</li>
          <li>Informações de cada empreendimento</li>
          <li>Filtros de buscas para a obra que deseja</li>
          <li>Filtros de buscas para a obra que deseja</li>
          <li>Materiais para treinamento: abordando clientes, etc</li>
          <li>Materiais para treinamento: abordando clientes, etc</li>
          <li>Contato com o coordenador: tirar dúvidas de maneira rápida e eficaz</li>
          <li>Contato com o coordenador: tirar dúvidas de maneira rápida e eficaz</li>
          <li>Ambiente seguro e personalizado acessível de qualquer lugar</li>
          <li>Ambiente seguro e personalizado acessível de qualquer lugar</li>
        </ul>
      </div>
    </div> -->

    <br><br>

    <!-- LOCATION -->
    <div class="prodetail__location" id="localizacao">
      <div class="prodetail__location--map">
        <input type="hidden" value="{{product.Endereco?.Logradouro + ' ' + product.Endereco?.Numero + ', ' + product.Endereco?.Cidade + ', ' + product.Endereco?.Estado + ', BRA'}}" #fulladdress>
        <agm-map *ngIf="mapInfos" [latitude]="location.lat" [longitude]="location.lng" [zoom]="15">
          <agm-circle [fillColor]="fillColor" [fillOpacity]="0.6" [latitude]="location.lat" [longitude]="location.lng"
                      [radius]="circleRadius" [strokeColor]="fillColor" [strokeWeight]="strokeWeight"></agm-circle>
        </agm-map>

      </div>
      <div class="prodetail__location--address">
          <span>
            {{product.Endereco?.Logradouro}}, {{product.Endereco?.Numero}}<br>
            {{product.Endereco?.Bairro}}, {{product.Endereco?.Cidade}} - {{product.Endereco?.Estado}},
            {{product.Endereco?.Cep}}
          </span>
        <!-- <ul>
          <li>Proximidades</li>
          <li>Fotos do apartamento para download</li>
          <li>Informações de cada empreendimento</li>
          <li>Filtros de buscas para a obra que deseja</li>
          <li>Materiais para treinamento: abordando clientes, etc</li>
          <li>Contato com o coordenador: tirar dúvidas de maneira rápida e eficaz</li>
          <li>Ambiente seguro e personalizado acessível de qualquer lugar</li>
        </ul> -->
      </div>
    </div>

    <div class="container" id="documentos">
      <div class="profeatures">
        <h4>Documentos</h4>
        <hr>
        <br>
        
          <ng-container *ngFor="let doc of product.documentos">
            <div style="display: flex; align-items: center;justify-content: center; background-color: gray; margin-bottom: 10px;">
              <div>
                <img src="assets/icons/ico_document.svg" [alt]="doc.descricao" style="cursor: pointer; align-items: center;" />
              </div>
              <br>
              <div style="margin-left: 20px;"> 
                <a [href]="doc.caminho | replaceHttp" download style="text-decoration: none; color: white; font-weight: bold;">
                  {{doc.descricao}}
                </a>
            </div>
            </div>
          </ng-container>
        
      </div>
    </div>
    <!-- <div class="container" id="documentos">
      <div class="profeatures">
        <h4>Documentos</h4>
        <hr>
        <br>
        <a class="document" href="#">
          <div class="document__info">
            <img alt="" src="assets/icons/ico_document.svg">
            <div class="document__addr">
              <span>Jardim Petrópolis</span>
              <span>Add Vila Mariana</span>
            </div>
          </div>
          <span class="document__download">Baixar</span>
        </a>

        <a class="document" href="#">
          <div class="document__info">
            <img alt="" src="assets/icons/ico_document.svg">
            <div class="document__addr">
              <span>Jardim Petrópolis</span>
              <span>Add Vila Mariana</span>
            </div>
          </div>
          <span class="document__download">Baixar</span>
        </a>

        <a class="document" href="#">
          <div class="document__info">
            <img alt="" src="assets/icons/ico_document.svg">
            <div class="document__addr">
              <span>Jardim Petrópolis</span>
              <span>Add Vila Mariana</span>
            </div>
          </div>
          <span class="document__download">Baixar</span>
        </a>

        <a class="document" href="#">
          <div class="document__info">
            <img alt="" src="assets/icons/ico_document.svg">
            <div class="document__addr">
              <span>Jardim Petrópolis</span>
              <span>Add Vila Mariana</span>
            </div>
          </div>
          <span class="document__download">Baixar</span>
        </a>

        <a class="document" href="#">
          <div class="document__info">
            <img alt="" src="assets/icons/ico_document.svg">
            <div class="document__addr">
              <span>Jardim Petrópolis</span>
              <span>Add Vila Mariana</span>
            </div>
          </div>
          <span class="document__download">Baixar</span>
        </a>
      </div>
      <app-pagination [page]="1"></app-pagination>
    </div> -->
  <ng-container>
</ng-container>
