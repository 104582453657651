import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ConfirmedValidator } from '../register-password/confirmed.validators';
import { AuthFacade } from '../+state/auth.facade';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  passwordReset: FormGroup;
  submitted = false;

  constructor(
    private authFacade: AuthFacade,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.passwordReset = this.formBuilder.group({
      code: [this.route.snapshot.queryParamMap.get('code')],
      password: ['', [Validators.required, Validators.minLength(8)]],
      passwordConfirmation: ['', [Validators.required]]
    }, {
      validators: ConfirmedValidator('password', 'passwordConfirmation')
    });
  }

  get f(): { [key: string]: AbstractControl; } {
    return this.passwordReset.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.passwordReset.valid) {
      this.authFacade.resetPassword(this.passwordReset.value);
    }
  }

}
