import { Injectable } from '@angular/core';
import { CompaniesFacade } from '../../companies/+state/companies.facade';

@Injectable({
  providedIn: 'root'
})
export class TenantService {
  constructor(private companiesFacade: CompaniesFacade) {
  }

  getTenant(): void {
    this.getTenantForHostname(location.hostname);
  }

  getTenantForHostname(hostname: string): void {
    this.getTenantForHost(hostname.split('.')[0]);
  }

  getTenantForHost(host: string): void {
    this.companiesFacade.getCompany(host);
  }
}
