import { Action, createReducer, on } from '@ngrx/store';

import * as CompaniesActions from './companies.actions';
import { Status } from '../../shared/interfaces/state.interface';
import { Company } from '../../shared/interfaces/company.interface';

export const companiesFeatureKey = 'companies';

export interface CompaniesState {
  company: Company;
  status: Status;
}

export interface CompanyStore {
  readonly [companiesFeatureKey]: CompaniesState;
}

export const companiesInitialState: CompaniesState = {
  status: Status.INIT,
  company: null
};

const reducer = createReducer(
  companiesInitialState,
  on(CompaniesActions.getCompany, (state, _) => ({
    ...state,
    status: Status.IN_PROGRESS,
  })),
  on(CompaniesActions.getCompanySuccess, (state, action) => ({
    ...state,
    status: Status.INIT,
    company: action.company
  })),
  on(CompaniesActions.getCompanyFail, (state, action) => ({
    ...companiesInitialState,
  })),
);

export function companiesReducer(state: CompaniesState | undefined, action: Action): CompaniesState {
  return reducer(state, action);
}
