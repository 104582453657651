import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Company } from '../shared/interfaces/company.interface';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  constructor(private httpClient: HttpClient) {
  }

  getCompanies(): Observable<Company[]> {
    return this.httpClient.get<Company[]>(environment.api_url + '/companies')
      .pipe(map(res => {
        //console.log(res);
        return res;
      }));
  }

  getCompanyBySubdomain(subdomain: string): Observable<Company> {
    return this.httpClient.get<Company>(environment.api_url + '/companies?subdomain=' + subdomain)
      .pipe(map(res => res[0]));
  }

}
