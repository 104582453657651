<div class="myaccount header_padding">
  <div class="container" *ngIf="user$ | async as user">
    <h1>Dados de Cadastro</h1>

    <div class="form__default login__form">

      <h4 class="hide-on-xs">Informações do usuário</h4>
      <div class="hr hide-on-xs"></div>
      <div class="show-on-xs">
        <h4>Dados de cadastro</h4>
        <div class="hr"></div>
      </div>
      <form [formGroup]="dataForm" class="form-row">

        <ng-container *ngIf="user.perfil === UserPerfil.IMOBILIARIA">
          <div class="form-group">
            <label [ngClass]="{ 'is-invalid-label': submitted && f.razao_social.errors }">Razão Social</label>
            <input [ngClass]="{ 'is-invalid': submitted && f.razao_social.errors }" formControlName="razao_social"
                   type="text">
            <div *ngIf="submitted && f.razao_social.errors" class="invalid-feedback">
              <div *ngIf="f.razao_social.errors.required">Campo Obrigatório</div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="user.perfil === UserPerfil.AUTONOMO">
          <div class="form-group">
            <label [ngClass]="{ 'is-invalid-label': submitted && f.nome.errors }">Nome</label>
            <input type="text" formControlName="nome" [ngClass]="{ 'is-invalid': submitted && f.nome.errors }">
            <div *ngIf="submitted && f.nome.errors" class="invalid-feedback">
              <div *ngIf="f.nome.errors.required">Campo Obrigatório</div>
            </div>
          </div>
        </ng-container>

        <div class="form-group">
          <label [ngClass]="{ 'is-invalid-label': submitted && f.email.errors }">E-mail</label>
          <input type="email" formControlName="email" placeholder="Informe um e-mail válido"
                 [ngClass]="{ 'is-invalid': submitted && f.email.errors }" required>
          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">Campo Obrigatório</div>
            <div *ngIf="f.email.errors.pattern">Necessário email válido</div>
          </div>
        </div>

        <div class="form-group">
          <label [ngClass]="{ 'is-invalid-label': submitted && f.telefone.errors }">Telefone</label>
          <input type="text" formControlName="telefone" placeholder="(XX) XXXXX-XXXX" mask="(00) 00000-0000"
                 [ngClass]="{ 'is-invalid': submitted && f.telefone.errors }">
          <div *ngIf="submitted && f.telefone.errors" class="invalid-feedback">
            <div *ngIf="f.telefone.errors.required">Campo Obrigatório</div>
          </div>
        </div>

        <ng-container *ngIf="user.perfil === UserPerfil.IMOBILIARIA">
          <div class="form-group">
            <label [ngClass]="{ 'is-invalid-label': submitted && f.cnpj.errors }">CNPJ</label>
            <input type="text" formControlName="cnpj" placeholder="" mask="00.000.000/0000-00"
                   [ngClass]="{ 'is-invalid': submitted && f.cnpj.errors }">
            <div *ngIf="submitted && f.cnpj.errors" class="invalid-feedback">
              <div *ngIf="f.cnpj.errors.required">Campo Obrigatório</div>
              <div *ngIf="f.cnpj.errors.cnpjvalidator && (f.cnpj.dirty || f.cnpj.touched)">CNPJ Inválido</div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="user.perfil === UserPerfil.AUTONOMO">
          <div class="form-group">
            <label [ngClass]="{ 'is-invalid-label': submitted && f.cpf.errors }">CPF</label>
            <input type="text" formControlName="cpf" mask="000.000.000-00"
                   [ngClass]="{ 'is-invalid': submitted && f.cpf.errors }" placeholder="">
            <div *ngIf="submitted && f.cpf.errors" class="invalid-feedback">
              <div *ngIf="f.cpf.errors.required">Campo Obrigatório</div>
              <div *ngIf="f.cpf.errors.cpfvalidator && (f.cpf.dirty || f.cpf.touched)">CPF Inválido</div>
            </div>
          </div>
        </ng-container>

        <div class="form-group">
          <label [ngClass]="{ 'is-invalid-label': submitted && f.creci.errors }">CRECI</label>
          <input type="text" formControlName="creci" placeholder=""
                 [ngClass]="{ 'is-invalid': submitted && f.creci.errors }">
          <div *ngIf="submitted && f.creci.errors" class="invalid-feedback">
            <div *ngIf="f.creci.errors.required">Campo Obrigatório</div>
          </div>
        </div>


      </form>

      <h4>Areas de atuação</h4>
      <div class="hr"></div>

      <ng-container *ngIf="districtsFromCRM.length;else other_content">

        <ng-container *ngFor="let region of regionsFromCRM">
  
          <div class="acc__header" (click)="toggleAccordion($event, 0);">{{region.nome}}</div>
          <div class="acc">
            <div class="acc__content">
              <ng-container *ngFor="let district of districtsFromCRM">
                <div *ngIf="district.id_regiao == region.id_regiao" class="acc__content--item">
                  <div class="checkbox">
                    <input (click)="addDistrict(district)"
                           [checked]="(arr_ids_districts.includes(district.id_bairro))" class="checkbox__input"
                           id="checkbox_a{{district.id_bairro}}"
                           type="checkbox">
                    <label for="checkbox_a{{district.id_bairro}}" class="checkbox__label">{{district.nome}}</label>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>

      </ng-container>
      <ng-template #other_content>

        <ng-container *ngIf="(districts$ | async) let districts">
  
          <ng-container *ngFor="let region of regions$ | async">
  
            <div class="acc__header" (click)="toggleAccordion($event, 0);">{{region.name}}</div>
            <div class="acc">
              <div class="acc__content">
                <ng-container *ngFor="let district of districts">
                  <div *ngIf="district?.region?.id == region.id" class="acc__content--item">
                    <div class="checkbox">
                      <input (click)="addDistrict(district)"
                             [checked]="(user.districts | where: ['id', district.id])?.length" class="checkbox__input"
                             id="checkbox_a{{district.id}}"
                             type="checkbox">
                      <label for="checkbox_a{{district.id}}" class="checkbox__label">{{district.name}}</label>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </ng-container>

      </ng-template>

      <div class="holder__btn">
        <app-button
          (click)="save()"
          [label]="'Salvar'"
          [btnstyle]="'btn_solid--blocked--primary'"></app-button>
        <br>
      </div>
    </div>
  </div>
</div>

