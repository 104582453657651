import { createFeatureSelector, createSelector } from '@ngrx/store';

import { companiesFeatureKey, CompaniesState } from './companies.reducer';

export const getCompaniesFeature = createFeatureSelector<CompaniesState>(companiesFeatureKey);
export const getCompany = createSelector(getCompaniesFeature, (companies: CompaniesState) => companies.company);

export const companiesQuery = {
  getCompany,
};
