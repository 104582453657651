import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-styleguide-app',
  templateUrl: './styleguide-app.component.html',
  styleUrls: ['./styleguide-app.component.scss']
})
export class StyleguideAppComponent implements OnInit {

  @ViewChild('intersection') section;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    //console.log(this.section);
  }

}
