export enum UserPerfil {
  AUTONOMO = 'Autônomo',
  IMOBILIARIA = 'Imobiliária',
}

export interface UserResponse {
  jwt: string;
  user: User;
}

export interface ResetPassword {
  code: string;
  password: string;
  passwordConfirmation: string;
}

export interface User {
  id: number;
  username: string;
  email: string;
  created_at?: Date;
  nome?: string;
  tipo?: string;
  cpf?: string;
  cnpj?: string;
  razao_social?: string;
  creci?: string;
  telefone?: string;
  ativo?: boolean;
  perfil: string;
  token: string;
  districts?: District[];
  coordenador?: string;
  politica_privacidade?: string;
  ids_districts?: string;
  nome_responsavel?: string;
  creci_responsavel?: string;
  cpf_responsavel?: string;
}

export interface NewUser {
  username: string;
  email: string;
  password: string;
  perfil: string;
}

export interface LoginUser {
  identifier: string;
  password: string;
}

export interface Region {
  id: number;
  name: string;
}

export interface District {
  id: number;
  name: string;
  region: Region;
}
